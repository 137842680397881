import React from 'react';
import {
  ScreenSize,
  getCurrentScreenSize
} from '../Services/ScreenSizeService';
import './Footer.css';
import CEIcon from '../Images/ec.svg';
import { Link } from 'react-router-dom';
import tcFile from '../Images/Terms-Conditions.pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faYoutubeSquare,
  faInstagramSquare
} from '@fortawesome/free-brands-svg-icons';

function Footer(): React.ReactElement {
  const [screenSize, setScreenSize] = React.useState(ScreenSize.MEDIUM);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // Handle Screen Size Stuff
    function UpdateScreenSize(): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    window.addEventListener('resize', UpdateScreenSize);
    UpdateScreenSize();
    return () => {
      window.removeEventListener('resize', UpdateScreenSize);
    };
  }, []);

  return (
    <div>
      {screenSize !== ScreenSize.SMALL && (
        <div className="footer-container">
          <div className="content-section">
            <div className="left-content">
              <p className="address">
                <b>
                  <i>Address:</i>
                </b>
                <br />
                JET THRUSTER NORTH AMERICA
                <br />
                2226 72ND DR. EAST
                <br />
                SARASOTA, FL 34243
              </p>
              <p className="contact-info">
                <b>
                  <i>Phone:</i>
                </b>
                <br />
                (855) 584-7878
                <br />
                (941) 212-5023
              </p>
            </div>
            <div className="middle-content">
              <div>
                <Link className="footer-nav-item" to="/">
                  Home
                </Link>
                <Link className="footer-nav-item" to="/systems">
                  Systems
                </Link>
                <Link className="footer-nav-item" to="/downloads">
                  Downloads
                </Link>
                <Link className="footer-nav-item" to="/contact">
                  Contact
                </Link>
                <Link className="footer-nav-item" to="/about">
                  About
                </Link>
              </div>
              <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                <div className="certified-content">
                  <img
                    src={CEIcon}
                    width={35}
                    alt="Eurpoean Conformity"
                    style={{ paddingTop: '19px', paddingLeft: '22px' }}
                  />
                  <p
                    className="certified-paragraph"
                    style={{
                      fontSize: '16px'
                    }}
                  >
                    Jet Thruster, a subsidiary of Holland Marine Parts, is
                    manufactured in The Netherlands according to CE regulations,
                    and tested to ABYC standards for use in North America.
                  </p>
                </div>
                <a
                  href={tcFile}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#202020',
                    textAlign: 'center',
                    textDecoration: 'none',
                    width: '100%'
                  }}
                >
                  <i>Jet Thruster North America Terms and Conditions</i>
                </a>
              </div>
            </div>
            <div className="right-content">
              <a
                href="https://www.youtube.com/channel/UCOqSUbIN0VR5mqYSZEntY5A"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon
                  icon={faYoutubeSquare}
                  size="2x"
                  color="black"
                />
              </a>
              <a
                href="https://www.facebook.com/jetthrusteramerica"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  size="2x"
                  color="black"
                />
              </a>
              <a
                href="https://www.instagram.com/jet_thruster_america/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  size="2x"
                  color="black"
                />
              </a>
              <a
                href="https://jetthrusterblog.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
                style={{ color: 'black' }}
              >
                <i>BLOG</i>
              </a>
            </div>
          </div>
        </div>
      )}

      {screenSize === ScreenSize.SMALL && (
        <div className="footer-container">
          <div className="content-section-small">
            <div className="social-section-small">
              <a
                href="https://www.youtube.com/channel/UCOqSUbIN0VR5mqYSZEntY5A"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-small"
              >
                <FontAwesomeIcon
                  icon={faYoutubeSquare}
                  size="2x"
                  color="#202020"
                />
              </a>
              <a
                href="https://www.facebook.com/jetthrusteramerica"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-small"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  size="2x"
                  color="#202020"
                />
              </a>
              <a
                href="https://www.instagram.com/jet_thruster_america/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-small"
              >
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  size="2x"
                  color="#202020"
                />
              </a>
              <a
                href="https://jetthrusterblog.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-small"
              >
                <i>BLOG</i>
              </a>
              <a
                href={tcFile}
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-small"
              >
                <i>T&C</i>
              </a>
            </div>
            <div className="left-content">
              <p className="address">
                <b>
                  <i>Address:</i>
                </b>
                <br />
                JET THRUSTER NORTH AMERICA
                <br />
                2226 72ND DR. EAST
                <br />
                SARASOTA, FL 34243
              </p>
              <p className="contact-info">
                <b>
                  <i>Phone:</i>
                </b>
                <br />
                (855) 584-7878
                <br />
                (941) 212-5023
              </p>
            </div>
            <div className="certified-content-small">
              <p className="certified-paragraph">
                All products are manufactured according to CE regulations, and
                tested to ABYC standards.
              </p>
            </div>
            <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                <div>
                  <img
                    src={CEIcon}
                    width={35}
                    alt="Eurpoean Conformity"
                    style={{ paddingTop: '19px', paddingLeft: '22px' }}
                  />
                </div>
              <div className="certified-content">
                <p
                  className="certified-paragraph"
                  style={{
                    fontSize: '16px'
                  }}
                >
                  Jet Thruster, a subsidiary of Holland Marine Parts, is
                  manufactured in The Netherlands according to CE regulations,
                  and tested to ABYC standards for use in North America.
                </p>
              </div>
              <a
                href={tcFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#202020',
                  textAlign: 'center',
                  textDecoration: 'none',
                  width: '100%'
                }}
              >
                <i>Jet Thruster North America Terms and Conditions</i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
