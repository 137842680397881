import React from 'react';
import './PlayButton.css';

function PlayButton (): React.ReactElement {
  return (
    <div className='circle'>
      <div className='play'></div>
    </div>
  );
};

export default PlayButton;
