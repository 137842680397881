export class JtSpecModel {
  constructor (
    public force: string,
    public runVoltageDC: string,
    public wattage: string,
    public current: string,
    public fuseRating: string,
    public minimumBatteryCapacity: string,
    public optionalBatteryChargeVoltage: string,
    public recommendedBattery: string,
    public recommendedBatteryCable: string,
    public waterInletSize: string,
    public dischargeNozzleOutletSizes: string,
    public pressureHoseDiameter: string,
    public weight: string,
    public horizontalDimensions: string,
    public verticalDimensions: string
  ) {}
}
