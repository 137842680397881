import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Alert } from '@mui/material';
import React, { useState } from 'react';
import './ContactSupportDialog.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitSupportForm } from '../../Services/FormSubmissionService';
import { FormImageSubmission } from '../../Models/FormImageSubmission';
import { SupportFormModel } from '../../Models/SupportFormModel';
import { ImageFilesAllowed } from '../../Constants/FileTypesAllowed';
// import { BACKEND_APP_URL } from '../../config';

interface DialogProps {
  isSupportDialogOpen: boolean;
  closeSupportDialog: () => void;
}

const ContactSupportDialog: React.FC<DialogProps> = ({ isSupportDialogOpen, closeSupportDialog }) => {
  // Define your state variables with their default values
  const defaultTypeOfBoat = '';
  const defaultBoatLength = '';
  const defaultBoatName = '';
  const defaultCustomerEmail = '';
  const defaultCustomerPhoneNumber = '';
  const defaultCustomerQuestionOrComment = '';
  const defaultImageFile = null;
  const defaultComponentWithIssue = '';
  const defaultSpecificInfo = '';
  const defaultRecaptchaValue = null;
  const defaultIsSubmitting = false;

  // Use useState to initialize state variables
  const [typeOfBoat, setTypeOfBoat] = useState(defaultTypeOfBoat);
  const [boatLength, setBoatLength] = useState(defaultBoatLength);
  const [boatName, setBoatName] = useState(defaultBoatName);
  const [customerEmail, setCustomerEmail] = useState(defaultCustomerEmail);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(defaultCustomerPhoneNumber);
  const [customerQuestionOrComment, setCustomerQuestionOrComment] = useState(defaultCustomerQuestionOrComment);
  const [imageFile, setImageFile] = useState<File | null>(defaultImageFile);
  const [componentWithIssue, setComponentWithIssue] = useState(defaultComponentWithIssue);
  const [specificInfo, setSpecificInfo] = useState(defaultSpecificInfo);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(defaultRecaptchaValue);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(defaultIsSubmitting);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState<boolean>(false);

  // Function to reset all state variables to their default values
  const resetToDefaults = (): void => {
    setTypeOfBoat(defaultTypeOfBoat);
    setBoatLength(defaultBoatLength);
    setBoatName(defaultBoatName);
    setCustomerEmail(defaultCustomerEmail);
    setCustomerPhoneNumber(defaultCustomerPhoneNumber);
    setCustomerQuestionOrComment(defaultCustomerQuestionOrComment);
    setImageFile(defaultImageFile);
    setComponentWithIssue(defaultComponentWithIssue);
    setSpecificInfo(defaultSpecificInfo);
    setRecaptchaValue(defaultRecaptchaValue);
  };
  // Validation for email input
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmail);
  const containsLetters = /[a-zA-Z]/.test(customerPhoneNumber);

  const isSubmitDisabled =
  (typeOfBoat.length === 0) ||
  (boatLength.length === 0) ||
  (boatName.length === 0) ||
  (customerEmail.length === 0) ||
  !isEmailValid ||
  (customerPhoneNumber.length === 0) ||
  (specificInfo.length === 0) ||
  (componentWithIssue.length === 0) ||
  (recaptchaValue == null); // Include reCAPTCHA in the check;

  const handleClose = (): void => {
    resetToDefaults();
    closeSupportDialog();
    setSubmittedSuccessfully(false);
    // if (isSubmitting) {
    //   resetToDefaults();
    //   closeSupportDialog();
    // }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];

    if (file != null) {
      const alerts: string[] = [];

      if (file.size > 10 * 1024 * 1024) {
        alerts.push('Image size should be less than 3MB.');
      }

      const fileExtension: string | undefined = file.name.split('.').pop();
      if (!ImageFilesAllowed.includes(fileExtension?.toLowerCase() ?? '')) {
        alerts.push('Only image files are allowed.');
      }

      if (alerts.length > 0) {
        alert(alerts.join('\n'));
        return;
      }

      setImageFile(file);
    }
  };

  const handleSubmit = (): void => {
    setIsSubmitting(true);
    if (recaptchaValue != null) {
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      SubmitSupportForm(new SupportFormModel(
        typeOfBoat,
        boatLength,
        boatName,
        customerEmail,
        customerPhoneNumber,
        customerQuestionOrComment,
        specificInfo,
        componentWithIssue,
        '',
        imageFile,
        recaptchaValue
      )).then((response) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        // if (!response) {
        //   throw new Error('Form submission failed');
        // }
        setSubmittedSuccessfully(true);
        setTimeout(() => {
          setIsSubmitting(false);
          handleClose();
        }, 1500);
        // showSuccessMessage();
      })
        .catch((error) => {
          console.error(error);
          setIsSubmitting(false);
          // handleClose();
        });
    }
  };

  return (
    <Modal open={isSupportDialogOpen} onClose={handleClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 300,
          maxWidth: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          maxHeight: '80vh', // Set a maximum height based on viewport height (80% of viewport height)
          overflowY: 'auto' // Enable vertical scrolling if the content overflows the max height

        }}
      >
        <h2 id="modal-title">Contact Support</h2>
        <FormControl fullWidth required className='input-field'>
          <InputLabel id="component-issue-dropdown-label">Type of Boat</InputLabel>
          <Select
            labelId="component-issue-dropdown-label"
            id="component-issue-dropdown"
            value={typeOfBoat}
            onChange={(e) => { setTypeOfBoat(e.target.value); }}
            label = 'Type of Boat'
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value="Motor">Motor</MenuItem>
            <MenuItem value="Sail">Sail</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Boat Length"
          value={boatLength}
          onChange={(e) => { setBoatLength(e.target.value); }}
          fullWidth
          required
          className='input-field'
          sx={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <TextField
          label="Boat Name"
          value={boatName}
          onChange={(e) => { setBoatName(e.target.value); }}
          fullWidth
          required
          className='input-field'
          sx={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <FormControl fullWidth required className='input-field'>
          <InputLabel id="component-issue-dropdown-label">Component with Issue</InputLabel>
          <Select
            labelId="component-issue-dropdown-label"
            id="component-issue-dropdown"
            value={componentWithIssue}
            onChange={(e) => { setComponentWithIssue(e.target.value); }}
            label = 'Component with Issue'
            sx={{ textAlign: 'left',marginTop: '5px', marginBottom: '5px' }}
          >
            <MenuItem value="Pump">Pump</MenuItem>
            <MenuItem value="Valve">Valve</MenuItem>
            <MenuItem value="Nozzle">Nozzle</MenuItem>
            <MenuItem value="Electric">Electric</MenuItem>
            <MenuItem value="Ball Valve">Ball Valve</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth required className='input-field' sx={{ marginTop: '5px', marginBottom: '5px' }}>
          <InputLabel id="specific-info-dropdown-label">Jet Thruster Model</InputLabel>
          <Select
            labelId="specific-info-dropdown-label"
            id="specific-info-dropdown"
            value={specificInfo}
            onChange={(e) => { setSpecificInfo(e.target.value); }}
            label = 'Jet Thruster Model'
            sx={{ textAlign: 'left' }}
          >
            <MenuItem value="Jt 30">Jt 30</MenuItem>
            <MenuItem value="Jt 50">Jt 50</MenuItem>
            <MenuItem value="Jt 70">Jt 70</MenuItem>
            <MenuItem value="Jt 90">Jt 90</MenuItem>
            <MenuItem value="Micro">Micro</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Email"
          value={customerEmail}
          onChange={(e) => { setCustomerEmail(e.target.value); }}
          fullWidth
          required
          error={!isEmailValid && customerEmail.length > 0}
          helperText={!isEmailValid && customerEmail.length > 0 ? 'Email format is Invalid.' : ''}
          className='input-field'
          sx={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <TextField
          label="Phone Number"
          type="tel" // Set the input type to "tel"
          inputProps={{
            pattern: '[0-9]*' // Allow only numbers
          }}
          value={customerPhoneNumber}
          onChange={(e) => { setCustomerPhoneNumber(e.target.value); }}
          error={containsLetters && customerPhoneNumber.length > 0}
          helperText={containsLetters && customerPhoneNumber.length > 0 ? 'Phone Number can not contain letters.' : ''}
          fullWidth
          required
          className='input-field'
          sx={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <TextField
          label="Describe Issue or Question"
          value={customerQuestionOrComment}
          onChange={(e) => { setCustomerQuestionOrComment(e.target.value); }}
          multiline
          rows={4}
          fullWidth
          className='input-field'
          variant="standard"
          sx={{ marginTop: '5px', marginBottom: '5px' }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="imageUpload"
          className='input-field'
        />
        <label htmlFor="imageUpload" style = {{ margin: '10px' }}>
          <Button variant="contained" component="span" className='upload-image'>
            Upload Image (Max 3MB)
          </Button>
        </label>
        <br />
        <p style = {{ margin: '10px' }}>{(imageFile != null) ? `Selected: ${imageFile.name}` : 'No image selected'}</p>
        {(imageFile !== null) && (
          <div>
            <br />
            <img
              src={URL.createObjectURL(imageFile)} // Use URL.createObjectURL to display the image
              alt="Submitted Image"
              style={{ maxWidth: '100%', maxHeight: '200px', margin: '10px' }}
            />
          </div>
        )}
          <br />
        <div style = {{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <ReCAPTCHA
            sitekey="6Ld3TU0oAAAAAG8WpRNlYn-9cFj9EfbuI2MbMijF"
            onChange={(value) => { setRecaptchaValue(value); }}
            onError={(e) => { console.error(e); }}
          />
        </div>
        {!submittedSuccessfully && isSupportDialogOpen && (

          <div style = {{ paddingTop: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitDisabled || isSubmitting}
              style={{ marginRight: '25px' }}
              className='submit-button'
              id='contact-support-submission-button'
            >
              {isSubmitting
                ? (
                <CircularProgress size={24} color="inherit" id='contact-support-submission-button' />
                  )
                : (
                    <span id='contact-support-submission-button'>Submit</span>

                  )}
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className='cancel-button' disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        )}
        {submittedSuccessfully && isSupportDialogOpen && (
          <Alert>
            Successfully Submitted!
          </Alert>
        )}

        {!submittedSuccessfully && isSupportDialogOpen && (
          <small>* indicates required fields.</small>
        )}
      </Box>
    </Modal>
  );
};

export default ContactSupportDialog;
