import { SlideItem } from '../Models/SlideItem';
import boat1 from '../Images/boat1.png';
import boat2 from '../Images/boat2.png';
import boat3 from '../Images/boat3.png';
import boat4 from '../Images/boat4.png';
import boat5 from '../Images/boat5.png';
import boat6 from '../Images/boat6.png';
export const Slides: SlideItem[] = [
  new SlideItem(
    '',
    '',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner/Lady-Boat_Desktop_rc.jpg',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner-mobile/Lady-Boat_Mobile_rc.jpg',
    '',
    ''
  ),
  new SlideItem(
    '',
    '',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner/Blue-Boat_Desktop_lc.jpg',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner-mobile/Blue-Boat_Mobile_rc.jpg',
    '',
    ''
  ),
  new SlideItem(
    '',
    '',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner/Boat-Spin_Desktop_rc.jpg',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner-mobile/Boat-Spin_Mobile_rt.jpg',
    '',
    ''
  ),
  new SlideItem(
    '',
    '',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner/Dock-Hold_Desktop_lc.jpg',
    'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/banner-mobile/Dock-Hold_Mobile_lb.jpg',
    '',
    ''
  )

];

export const TestimonialSlides: SlideItem[] = [
  new SlideItem(
    '',
    '',
    boat1,
    '',
    '/downloads',
    ''
  ),
  new SlideItem(
    '',
    '',
    boat2,
    '',
    '/downloads',
    ''
  ),
  new SlideItem(
    '',
    '',
    boat3,
    '',
    '/downloads',
    ''
  ),
  new SlideItem(
    '',
    '',
    boat4,
    '',
    '/downloads',
    ''
  ),
  new SlideItem(
    '',
    '',
    boat5,
    '',
    '/downloads',
    ''
  ),
  new SlideItem(
    '',
    '',
    boat6,
    '',
    '/downloads',
    ''
  )
];
