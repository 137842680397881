export interface SlideshowProps {
  slides: SlideItem[];
}

export class SlideItem {
  title: string;
  description: string;
  imageUrl: string;
  shortImageUrl: string;
  link: string;
  linkText: string;

  constructor (
    title: string,
    description: string,
    imageUrl: string,
    shortImageUrl: string,
    link: string,
    linkText: string
  ) {
    this.title = title;
    this.description = description;
    this.imageUrl = imageUrl;
    this.link = link;
    this.linkText = linkText;
    this.shortImageUrl = shortImageUrl;
  }
}
