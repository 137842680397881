import React from 'react';
import '../../SharedStyles.css';
import { Helmet } from 'react-helmet';
import PageBanner from '../../Components/PageBanner';
import './Components.css';
import { Link } from 'react-router-dom';

function Components (): React.ReactElement {
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);

  React.useEffect(() => {
    const handlePageLoad = (): void => {
      const hash = window.location.hash;

      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement !== null) {
        setTimeout(function (): void {
          targetElement.scrollIntoView({
            behavior: 'smooth' // Add this option for smooth scrolling
          });
        }, 250);
      }
    };

    // Check if the document is already in the 'complete' state
    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      // If not, add an event listener for the 'load' event
      window.addEventListener('load', handlePageLoad);
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, []); // Empty dependency array to run the effect only once after the initial render

  return (
      <div style={{
        width: '100%',
        marginTop: '20px'
      }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jet Thruster America - Components</title>
          <link rel="canonical" href="http://jetthrusteramerica.com/systems/components" />
          <meta
            name="description"
            content="Explore the essential components of Jet Thruster America's high-performance jet thrusters. Discover detailed specifications, uses, and the engineering behind each component to understand their roles in propulsion."
        />
        </Helmet>

        {/* <PageBanner
          ScreenImage='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/components/ComponentsBanner.jpg'
        /> */}

        {/* header section */}
        <div style = {{
          display: 'flex',
          backgroundColor: '#F1F1F1',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          borderBottom: '2px solid darkgrey',
          marginBottom: '25px'
        }}>
          <h1 style = {{ marginBlock: '0', marginLeft: '25px', marginTop: '40px', marginBottom: '20px' }}>
            Component Information below
          </h1>
        </div>

        {/* secondary header section */}
        <div style = {{
          display: 'flex',
          alignItems: 'baseline',
          borderBottom: '5px solid hsl(345, 96%, 42%)',
          padding: '30px',
          paddingBottom: '40px',
          gap: '5%',
          justifyContent: 'space-around'
        }}>
          <div style = {{ display: 'flex', flexDirection: 'column' }}>
            <h3 style = {{ marginBlockEnd: '0px', marginBlockStart: '0px', marginBottom: '10px' }}>
              System Components
            </h3>
            <small style = {{ color: '#3887FF' }}>
              View Component Information
            </small>
          </div>

          <div style = {{ display: 'flex', flexDirection: 'column' }}>
            <h3 style = {{ marginBlockEnd: '0px', marginBlockStart: '0px', marginBottom: '10px' }}>Explore Systems</h3>
            <small style = {{ color: '#3887FF', textDecoration: 'underline', cursor: 'pointer' }}><Link style = {{ all: 'unset' }} to='/systems'>Explore our Systems</Link></small>
          </div>
        </div>

        <div className='component-container' id='pumps'>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/30/JT-30.png' alt='JT-30 Pump Image' />
          </div>
          <div className='component-text-container'>
            <h2 className='component-header'>Pumps</h2>
            <p className='component-description'>
            Jet Thruster Pumps are the heart of the Jet Thruster System. Our pump heads are built from high strength composite material, and our proprietary impeller design is the secret to Jet Thruster performance and efficiency. For safe, on demand performance, stock pump models utilize high power DC motors. Hydraulic and specialized AC Induction Pump Motors are also available. For boats large or small, Jet Thruster America has a pump that will fit your needs.</p>
            </div>
        </div>
        <div className='component-container' id='3-way-valves'>
          <div className='component-text-container'>
            <h2 className='component-header'>3-Way Valves</h2>
            <p className='component-description'>
              At the core of the unique attitude control capability of Jet Thruster lies our innovative 3-way valve. Expertly engineered to maximize efficiency and reduce back pressure, these valves control the direction of water flow to thrust nozzles. Manufactured in both composite and stainless steel, they guarantee reliability in diverse marine conditions, and can be remotely located in any location on the vessel, allowing maximum flexibility.
            </p>
          </div>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/3wayvalve.png' alt='Three Way Valve Example' />
          </div>
        </div>
        <div className='component-container' id='nozzles'>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/nozzle3.png' alt="Composite Nozzle" />
          </div>
          <div className='component-text-container'>
            <h2 className='component-header'>Nozzles</h2>
            <p className='component-description'>
              Jet Thruster nozzles are built from high strength composite material for maximum service life. Our nozzles are precisely machined and available in a variety of angles to conform to hull shape, and are an integral part of the Jet Thruster System. Optional materials and weld-in designs are available for non-standard installations.
            </p>
          </div>
        </div>
        <div className='component-container' id='accessories'>
          <div className='component-text-container'>
            <h2 className='component-header'>Accessories</h2>
            <p className='component-description'>
              Enhance your marine experience with Jet Thruster America&apos;s range of accessories, including Remote Control modules, additional control stations, purge valves for high performance planing hulls, and various third party battery chargers, monitors, and electrical components. Every accessory is designed or tested for seamless compatibility. Our accessories ensure optimal performance, safety, and longevity for your Jet Thruster system.
            </p>
          </div>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/controlpanel.png' alt='Control Panel Example' />
          </div>
        </div>

        <div className='component-container' id='voltage'>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/MicroBluePNG.png' alt ='Micro Diagram' />
          </div>
          <div className='component-text-container'>
            <h2 className='component-header'>Voltage</h2>
            <p className='component-description'>
              Jet Thruster requires independent DC sources for pump and control power. Control hardware draws 10-30amps of 12vdc, and ties directly to your existing 12vdc accessory source(typically house batteries). Control power MUST be independent of pump motor power. Under or over voltage conditions caused by pump operation will impede performance of control components.
              <br /><br />
              Pump motor &quot;Run Voltage&quot; varies by model. We STRONGLY recommend a dedicated battery bank for pump motor(s).  However, an accessory option for all JT model systems allows a lower &quot;Charge Voltage&quot; by doubling voltage to the pump motor only during operation. This option could allow the pump battery bank to charge from your existing DC power source, and is especially useful when lower voltage equipment such as anchor windlasses or electric winches must share the pump battery bank.
            </p>
          </div>
        </div>

        <div className='component-container' id='hose'>
          <div className='component-text-container'>
            <h2 className='component-header'>Hose</h2>
            <p className='component-description'>
              Our high pressure and crush resistant rubber hose is required in every Jet Thruster installation. All models utilize 2&quot;-3&quot; discharge hose to route water flow to remotely located thrust nozzles, allowing for the scalability and flexibility of Jet Thruster installations.
              <br /> <br />
              In horizontal pump systems, an additional 3&quot;-4&quot; intake hose is utilized to connect the intake assembly to the pump motor head, which allows isolation between the vessel hull and pump head.
              <br /> <br />
              Note: Vertical models also provide thru-hull isolation, see Isolation valves below
            </p>
          </div>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Hose.png' alt='CAD diagram of a hose' />
          </div>
        </div>

        <div className='component-container' id='isolation-valves'>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/components/nozzlewithhandle.png' alt='example of an isolation valve' style={{ minWidth: '250px', maxWidth: '400px' }}/>
          </div>
          <div className='component-text-container'>
            <h2 className='component-header'>Isolation Valves</h2>
            <p className='component-description'>
              Every Jet Thruster system utilizes a common discharge nozzle/valve assembly of between 2&quot;-3&quot; diameter, and TruDesign™ marine brand composite isolation valves are provided with each. 3&quot;-4&quot; ball valves are utilized with Horizontal model pumps to provide isolation at the remotely located composite intakes.
              <br /> <br />
              Micro and vertical JT pump models are designed to become an integral part of the hull, taking the place of a separate thru-hull body. TruDesign™  ball valves are attached to Vertical JT model pump heads, and Micro pump motor assemblies include an internal proprietary knife valve. Vertical models are often the preferred option, particularly when installation space is limited.
              <br /> <br />
              Contact our sales team for more information.
            </p>
          </div>
        </div>

        <div className='component-container' id='spare-parts'>
          <div className='component-text-container'>
            <h2 className='component-header'>Spare Parts</h2>
            <p className='component-description'>
              Jet Thruster is exposed to less damage, and requires less service, than ANY other alternative on the market. However, a wide variety of spare parts and options are available. Plus, Jet Thruster Systems can often be upgraded after initial installation to increase power and performance.
              <br /> <br />
              Batteries, Zincs, thru-hulls, isolation valves, and hose, are considered life limited components. Additionally, certain electrical components such as thermal protection sensors and DC contactors occasionally require replacement. Contact sales for all your spare parts and accessory needs.
            </p>
          </div>
          <div className='component-image-container'>
          <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/SpareParts.png' alt='diagram of a full system'/>
          </div>
        </div>

        <div className='component-container' id='batteries'>
          <div className='component-image-container'>
            <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/battery.jpg' alt ='a yellow top optima battery'/>
          </div>
          <div className='component-text-container'>
            <h2 className='component-header'>Batteries</h2>
            <p className='component-description'>
              Low voltage and/or reduced capacity battery banks for pump motors or control power, are the number one call to our technical support line! Understanding and appropriately managing both is crucial for reliable operation. For example, older or damaged batteries often hold normal voltage at rest, but sag dramatically under load.
              <br /> <br />
              Number and configuration of pump motor batteries will be dictated by the model system you choose(see specification tables). We STRONGLY recommend Optima® brand batteries with Jet Thruster. Optima&apos;s unique AGM design allow for longer discharge, with less risk of internal damage, and lower voltage sag. If you do not have detailed knowledge of the chemistry, internal resistance, and mechanical design of lead acid batteries, stick with Optima®.
              <br /> < br/>
              Quality batteries and proper maintenance will ensure many years of trouble free operation of your Jet Thruster system.
            </p>
          </div>
        </div>

        <div className='component-container' id='maintenance'>
          <div className='component-text-container'>
            <h2 className='component-header'>Maintenance</h2>
            <p className='component-description'>
              With proper use and routine vessel bottom cleaning, Jet Thruster is virtually maintenance free. Excessive under voltage usage will shorten the life of the pump motor, but for users who monitor battery voltage, motor brushes and windings typically last the life of the system. As with any marine equipment, corrosion from salt air and high humidity or wet compartments will damage pump motors, directional 3-way valves, and control hardware.
              <br /> <br />
              Proper bilge maintenance and cleanliness, along with use of spray sealants/lubricants, will extend the life of these components. Periods of long disuse should be avoided, and all isolation valves should be periodically closed and opened. Refer to the installation and owners manual for more details.
            </p>
          </div>
          <div className='component-image-container'>
          <img src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Maint.jpg' alt='picture of parts and diagrams on a table' />
          </div>
        </div>
      </div>
  );
}

export default Components;
