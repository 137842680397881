import React from 'react';
import Home from './Pages/Home';
import SystemBuilder from './Pages/Systems/SystemBuilder';
import Components from './Pages/Systems/Components';
import { Navigate } from 'react-router-dom';

const SystemCategories = React.lazy(async () => {
  const module = await import('./Pages/SystemCategories');
  return { default: module.default };
});

const About = React.lazy(async () => {
  const module = await import('./Pages/About');
  return { default: module.default };
});

const Contact = React.lazy(async () => {
  const module = await import('./Pages/Contact');
  return { default: module.default };
});

const Downloads = React.lazy(async () => {
  const module = await import('./Pages/Downloads');
  return { default: module.default };
});

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/*',
    element: <Home />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },

  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/downloads',
    element: <Downloads />
  },
  {
    path: '/systems',
    element: <SystemCategories />
  },
  {
    path: '/systems/builder',
    element: <SystemBuilder />
  },
  {
    path: '/systems/components',
    element: <Components />
  },
  // Existing URLS for /references Page WE DO NOT HAVE A REFERENCES PAGE SO SEND TO HOME
  {
    path: '/references/*',
    element: <Navigate to="/" />
  },
  // Home page
  {
    path: '/discover',
    element: <Navigate to="/" />
  },
  {
    path: '/discover/',
    element: <Navigate to="/" />
  },
  // Existing URLS for /components Page
  {
    path: '/jetthruster/optima-batteries/',
    element: <Navigate to="/systems/components#batteries" />
  },
  {
    path: '/components/*',
    element: <Navigate to="/systems/components" />
  },
  // Existing URLS for /about pages
  {
    path: '/about-us',
    element: <Navigate to="/about" />
  },
  {
    path: '/about-us/*',
    element: <Navigate to="/about" />
  },
  // Existing URLS for /downloads pages
  {
    path: '/download',
    element: <Navigate to="/downloads" />
  },
  {
    path: '/download/*',
    element: <Navigate to="/downloads" />
  },
  // Existing URLS for /systems pages
  {
    path: '/jetthruster',
    element: <Navigate to="/systems" />
  },
  {
    path: '/products',
    element: <Navigate to="/systems" />
  },
  {
    path: '/jetthruster/systems',
    element: <Navigate to="/systems" />
  },
  {
    path: '/jetthruster/systems/bow-thruster',
    element: <Navigate to="/systems/builder?placement=BOW" />
  },
  {
    path: '/jetthruster/systems/stern-thruster',
    element: <Navigate to="/systems/builder?placement=STERN" />
  },
  {
    path: '/jetthruster/systems/bow-and-stern-thruster',
    element: <Navigate to="/systems/builder?placement=COMBI" />
  },
  {
    path: '/jetthruster/systems/*',
    element: <Navigate to="/systems" />
  },
  {
    path: '/jetthruster/*',
    element: <Navigate to="/systems" />
  }
];

export default AppRoutes;
