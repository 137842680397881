import React, { useRef } from 'react';
import './HomePageSlideShow.css';
import type { SlideshowProps } from '../../Models/SlideItem';
import { ScreenSize, getCurrentScreenSize } from '../../Services/ScreenSizeService';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TIMER_INTERVAL = 6000;

const HomePageSlideShow: React.FC<SlideshowProps> = ({ slides }): React.ReactElement => {
  const [screenSize, setScreenSize] = React.useState(ScreenSize.MEDIUM);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [userInterrupt, setUserInterrupt] = React.useState(false);

  const handlePreviousSlide = (): void => {
    setUserInterrupt(true);
    currentSlide === 0
      ? setCurrentSlide(slides.length - 1)
      : setCurrentSlide(currentSlide - 1);
  };

  const handleNextSlide = (): void => {
    setUserInterrupt(true);
    setCurrentSlide(currentSlide => (currentSlide + 1) % slides.length);
  };

  React.useEffect(() => {
    if (userInterrupt) {
      return;
    }

    function UpdateScreenSize (): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    const interval = setInterval(() => {
      const imageElement = document.querySelector('.slideshow-image') as HTMLElement;
      if (imageElement !== null) {
        imageElement.style.opacity = '0';
        setTimeout(() => {
          setCurrentSlide((currentSlide + 1) % slides.length);
          imageElement.style.opacity = '1';
        }, 1000);
      }
    }, TIMER_INTERVAL);

    window.addEventListener('resize', UpdateScreenSize);
    UpdateScreenSize();

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', UpdateScreenSize);
    };
  }, [currentSlide, userInterrupt]);

  const sliderRef = useRef(null) as any;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
    // afterChange: (index: React.SetStateAction<number>) => { setCurrentSlide(index); }
  };

  const handlePrev = (): void => {
    sliderRef.current.slickPrev();
  };

  const handleNext = (): void => {
    sliderRef.current.slickNext();
  };

  return (
    <div style = {{ width: '100%' }}>
      <div style={{ position: 'relative' }}>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
        <div key={index}>
          <div style={{
            backgroundImage: `url(${window.innerWidth <= 600 ? slide.shortImageUrl : slide.imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: window.innerHeight < 700 ? '250px' : '350px',
            backgroundPosition: 'center',
            margin: 'auto',
            width: '100%'
          }}>
            <div className='slideshow-navigation-button-container'>
              <button className='slideshow-navigation-button' onClick={handlePrev}>&#10094;</button>

              {/* If first slide */}
              {index === 0 && (
                <div className='slide-one'>
                  <div style={{
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1rem',
                    borderTop: '1px solid white',
                    paddingTop: '10px',
                    borderBottom: '1px solid white',
                    paddingBottom: '10px',
                    textShadow: 'rgb(0, 0, 0) 3px 3px 1px',
                    fontFamily: 'Open Sans,Arial,sans-serif'
                  }}>
                    PERFECT FOR
                    <h1 style={{
                      color: 'white',
                      marginBlock: '0px',
                      textShadow: 'rgb(0, 0, 0) 5px 1px 1px',
                      fontFamily: 'Open Sans,Arial,sans-serif'
                    }}>
                      POWER OR SAIL
                    </h1>
                    OF ANY SIZE
                  </div>
                </div>
              )}

              {/* If second slide */}
              {index === 1 && (
                <div className='slide-two'>
                  <div style={{
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1rem',
                    borderTop: '1px solid white',
                    paddingTop: '10px',
                    borderBottom: '1px solid white',
                    paddingBottom: '10px',
                    textShadow: 'rgb(0, 0, 0) 3px 3px 1px',
                    fontFamily: 'Open Sans,Arial,sans-serif'
                  }}>
                    FLEXIBLE INSTALLATION
                    <h1 style={{
                      color: 'white',
                      marginBlock: '0px',
                      textShadow: 'rgb(0, 0, 0) 5px 1px 1px',
                      fontFamily: 'Open Sans,Arial,sans-serif'
                    }}>
                      SMALL THRU-HULLS
                    </h1>
                    NO VULNERABLE PROPS
                  </div>
                </div>
              )}

              {/* If third slide */}
              {index === 2 && (
                <div className='slide-three'>
                  <div style={{
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1rem',
                    borderTop: '1px solid white',
                    paddingTop: '10px',
                    borderBottom: '1px solid white',
                    paddingBottom: '10px',
                    textShadow: 'rgb(0, 0, 0) 3px 3px 1px',
                    fontFamily: 'Open Sans,Arial,sans-serif'
                  }}>
                    UNMATCHED
                    <h1 style={{
                      color: 'white',
                      marginBlock: '0px',
                      textShadow: 'rgb(0, 0, 0) 5px 1px 1px',
                      fontFamily: 'Open Sans,Arial,sans-serif'
                    }}>
                      PERFORMANCE
                    </h1>
                    AND MANEUVERABILITY
                  </div>
                </div>
              )}

              {/* If fourth slide */}
              {index === 3 && (
                <div className='slide-four'>
                  <div style={{
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1rem',
                    borderTop: '1px solid white',
                    paddingTop: '10px',
                    borderBottom: '1px solid white',
                    paddingBottom: '10px',
                    textShadow: 'rgb(0, 0, 0) 3px 3px 1px',
                    fontFamily: 'Open Sans,Arial,sans-serif'
                  }}>
                    LOW MAINTENANCE
                    <h1 style={{
                      color: 'white',
                      marginBlock: '0px',
                      textShadow: 'rgb(0, 0, 0) 5px 1px 1px',
                      fontFamily: 'Open Sans,Arial,sans-serif'
                    }}>
                      POWERFUL
                    </h1>
                    QUIET OPERATION
                  </div>
                </div>
              )}

              <button className='slideshow-navigation-button' onClick={handleNext}>&#10095;</button>
            </div>
          </div>
        </div>
        ))}
      </Slider>

      {/* buttons */}
      {/* <div onClick={handlePrev} style={{
        position: 'absolute',
        left: '0',
        top: '50%',
        transform: 'translate(0%, -50%)'

      }}>prev</div>
      <div onClick={handleNext} style={{
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translate(0%, -50%)'
      }}>next</div> */}
      </div>
    </div>
  );
};

export default HomePageSlideShow;
