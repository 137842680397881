// import React from 'react'

export const ScreenSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export function getCurrentScreenSize (): string {
  const smallWidthThreshold: number = 885;
  const mediumWidthThreshold: number = 1024;

  // const width = window.outerWidth < window.innerWidth ? window.outerWidth : window.innerWidth;
  const width = window.innerWidth;

  if (width < smallWidthThreshold) {
    return ScreenSize.SMALL;
  } else if (
    width >= smallWidthThreshold &&
    width < mediumWidthThreshold
  ) {
    return ScreenSize.MEDIUM;
  } else {
    return ScreenSize.LARGE;
  }
}
