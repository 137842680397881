import { JtSpecModel } from '../Models/JtSpecModel';

export const JtThirtyThreeKwSpecs: JtSpecModel = new JtSpecModel(
  '30 Kgf', // Force
  '12V', // Run Voltage DC
  '5.7kW', // Wattage
  '480A', // Current
  '500A', // Fuse Rating
  '900cca / 75ah', // Minimum Battery Capacity
  'Not Listed', // Optional Battery/Charge Voltage
  'Optima Yellow Top 75Ah', // Recommended Battery
  '3\' 2/0 AWG', // Recommended Battery Cable
  '90mm (3.5")', // Water Inlet Size
  '60mm (2.35")', // Discharge Nozzle Outlet Sizes
  '52mm (2")', // Pressure Hose Diameter
  '64lb (29kg)', // Weight
  '18.2" × 8.9" × 8.4"', // LxWxH (Horizontal)
  '8.4" × 8.9" × 15.7"' // LxWxH (Vertical)
);

export const JtFiftyTwentyFourVoltSpecs: JtSpecModel = new JtSpecModel(
  '50 Kgf', // Force
  '24V', // Run Voltage DC
  '11.5kW', // Wattage
  '480A', // Current
  '500A', // Fuse Rating
  '1800cca / 150ah', // Optional Battery/Charge Voltage
  '12V', // Minimum Battery Capacity
  'Optima Yellow Top 75Ah x2', // Recommended Battery
  '3\' 2/0 AWG', // Recommended Battery Cable
  '90mm (3.5")', // Water Inlet Size
  '60mm (2.35")', // Discharge Nozzle Outlet Sizes
  '58mm (2.25")', // Pressure Hose Diameter
  '66lb (30kg)', // Weight
  '19.5" x 8.9" × 10.78"', // LxWxH (Horizontal)
  '8.9" x 10.78" x 17.12"' // LxWxH (Vertical)
);

export const JtFiftyFourtyEightVoltSpecs: JtSpecModel = new JtSpecModel(
  '54 Kgf', // Force
  '48V', // Run Voltage DC
  '11.5kW', // Wattage
  '240A', // Current
  '275A', // Fuse Rating
  '1800cca / 150ah', // Optional Battery/Charge Voltage
  '24V', // Minimum Battery Capacity
  'Optima Yellow Top 48Ah x4', // Recommended Battery
  '3\' 2/0 AWG', // Recommended Battery Cable
  '90mm (3.5")', // Water Inlet Size
  '60mm (2.35")', // Discharge Nozzle Outlet Sizes
  '58mm (2.25")', // Pressure Hose Diameter
  '66lb (30kg)', // Weight
  '19.5" x 8.9" × 10.78"', // LxWxH (Horizontal)
  '8.9" x 10.78" x 17.12"' // LxWxH (Vertical)
);

export const JtSeventyTwentyFourVoltSpecs: JtSpecModel = new JtSpecModel(
  '70 Kgf',
  '24V',
  '20.4kW',
  '850A',
  '1000A (2x 500A)',
  '2480cca / 192ah',
  '12V',
  'Optima Yellow Top 55Ah x4',
  '3\' 4/0 AWG',
  '101mm (4")',
  '60mm (2.35")',
  '76mm (3")',
  '95lb (43kg)',
  '22.6" × 13.2" × 13.5"',
  '13.5" × 13.2" × 19.1"'
);

export const JtSeventyFourtyEightVoltSpecs: JtSpecModel = new JtSpecModel(
  '76 Kgf',
  '48V',
  '20.4kW',
  '425A',
  '450A',
  '2480cca / 192ah',
  '24V',
  'Optima Yellow Top 55Ah x4',
  '3\' 4/0 AWG',
  '101mm (4")',
  '60mm (2.35")',
  '76mm (3")',
  '95lb (43kg)',
  '22.6" × 13.2" × 13.5"',
  '13.5" × 13.2" × 19.1"'
);

export const JtNinetyTwentyFourVoltSpecs: JtSpecModel = new JtSpecModel(
  '90 Kgf',
  '24V',
  '22.8kW',
  '950A',
  '1000A (2x 500A)',
  '3600cca / 300ah',
  '12V',
  'Optima Yellow Top 75Ah x4',
  '3\' 4/0 AWG',
  '101mm (4")',
  '60mm (2.35")',
  '76mm (3")',
  '134lb (61kg)',
  '23.6" x 13.2" x 14.3"',
  '13.2" x 14.3" x 20.1"'
);

export const JtNinetyFourtyEightVoltSpecs: JtSpecModel = new JtSpecModel(
  '98Kgf',
  '48V',
  '22.1kW',
  '460A',
  '500A (2x 250A)',
  '3600cca / 300ah',
  '24V',
  'Optima Yellow Top 75Ah x4',
  '3\' 4/0 AWG',
  '101mm (4")',
  '60mm (2.35")',
  '76mm (3")',
  '134lb (61kg)',
  '23.6" x 13.2" x 14.3"',
  '13.2" x 14.3" x 20.1"'
);

export const Micro: JtSpecModel = new JtSpecModel(
  '30Kgf',
  '12V',
  '5.5kW',
  '440A',
  '500A',
  '900cca / 45ah',
  'N/A',
  'Optima Yellow Top 75Ah',
  '3\' 2/0 AWG',
  '90mm (3.5")',
  '36mm (1.42")',
  '52mm (2")',
  '24lb (29kg)',
  '12.4" × 12.3" × 13.3"',
  '12.4" × 12.3" × 13.3"'
);
