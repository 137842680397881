import React from 'react';
import './SlideShow.css';
import type { SlideshowProps } from '../../Models/SlideItem';

const TIMER_INTERVAL = 6000;

const Slideshow: React.FC<SlideshowProps> = ({ slides }): React.ReactElement => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [userInterrupt, setUserInterrupt] = React.useState(false);

  React.useEffect(() => {
    if (userInterrupt) {
      return;
    }

    const interval = setInterval(() => {
      const imageElement = document.querySelector('.slideshow-image') as HTMLElement;
      const overlayElement = document.querySelector('.slideshow-title') as HTMLElement;
      if (imageElement !== null) {
        imageElement.style.opacity = '0';
        overlayElement.style.opacity = '0';
        setTimeout(() => {
          setCurrentSlide((currentSlide + 1) % slides.length);
          imageElement.style.opacity = '1';
          overlayElement.style.opacity = '1';
        }, 1000);
      }
    }, TIMER_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide, userInterrupt]);

  return (
    <div className='slideshow-container'>
      <img className='slideshow-image' src={slides[currentSlide].imageUrl} alt={slides[currentSlide].title} />
      <h1 className='slideshow-title'>{slides[currentSlide].title}</h1>
    </div>
  );
};

export default Slideshow;
