import React from 'react';
import './SiteLoading.css';
import Spinner from '../Images/spinner-solid.svg';

function SiteLoading (): React.ReactElement {
  return (
    <div style = {{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
        <img src= {Spinner}
            width={'100px'}
            alt ='Loading Spinner'
            style={{
              animation: 'spin 2s linear infinite' // Add this line for the animation
            }} />
    </div>
  );
};

export default SiteLoading;
