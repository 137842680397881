import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../../SharedStyles.css';
import './SystemBuilder.css';

import {
  faBolt,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../../Components/Breadcrumb';
import ContactSalesDialog from '../../Components/ContactComponents/ContactSalesDialog';
import ThrusterCalculator from '../../Components/ThrusterCalculator';
import {
  JtFiftyFourtyEightVoltSpecs,
  JtFiftyTwentyFourVoltSpecs,
  JtNinetyFourtyEightVoltSpecs,
  JtNinetyTwentyFourVoltSpecs,
  JtSeventyFourtyEightVoltSpecs,
  JtSeventyTwentyFourVoltSpecs,
  JtThirtyThreeKwSpecs,
  Micro
} from '../../Constants/JtSytemSpecs';
import { JtWithProductImage } from '../../Constants/JtWithProductImage';
import DoubleImage from '../../Images/Double-Clean.png';
import EmptySystem from '../../Images/EmptyBoatSVG.svg';
import BowSystem from '../../Images/MicroBow.svg';
import DoubleSystem from '../../Images/MicroDouble.svg';
import SternSystem from '../../Images/MicroStern.svg';
import ChevronRight from '../../Images/chevron-right.svg';
import ChevronUp from '../../Images/chevron-up.svg';
import MultiPump from '../../Images/multi-pump.jpg';
import MultiPumpBoat from '../../Images/multi-pump-boat.png';

import { JtSpecModel } from '../../Models/JtSpecModel';
import {
  ScreenSize,
  getCurrentScreenSize
} from '../../Services/ScreenSizeService';
function SystemBuilder(): React.ReactElement {
  const [screenSize, setScreenSize] = React.useState(ScreenSize.MEDIUM);
  const [isCalculatorOpen, setIsCalculatorOpen] =
    React.useState<boolean>(false);

  const [isSalesDialogOpen, setIsSalesDialogOpen] = React.useState(false);
  const [placementSelection, setPlacementSelection] = React.useState('STERN');
  const [jtSelection, setJtSelection] = React.useState('30');
  const [jtSpecsForSelection, setJtSpecsForSelection] =
    React.useState<JtSpecModel>(JtThirtyThreeKwSpecs);
  const [expandInfoSection, setExpandInfoSection] =
    React.useState<boolean>(false);
  const [isHorizontal, setIsHorizontal] = React.useState<boolean>(true);
  const [isHighVoltage, setIsHighVoltage] = React.useState<boolean>(false);
  const [activeProductImage, setActiveProductImage] = React.useState<string>(
    JtWithProductImage.filter((x) => x.key === 'JT30')[0].image
  );
  const [currentImage, setCurrentImage] = React.useState<string>('pump');
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const [openSection, setOpenSection] = React.useState<string | null>(null);

  const boatDiagramRef = React.useRef<HTMLImageElement | null>(null);
  const pumpAssemblyRef = React.useRef<HTMLImageElement | null>(null);
  const pumpAssemblyTitleRef = React.useRef<HTMLImageElement | null>(null);
  const pumpAssemblyInfoTableRef = React.useRef<HTMLImageElement | null>(null);

  const location = useLocation();

  const handleSectionClick = (sectionId: string): any => {
    if (sectionId === openSection) {
      // If the clicked section is already open, close it.
      setOpenSection(null);
    } else {
      // Otherwise, open the clicked section and close others.
      setOpenSection(sectionId);
    }
  };

  const openSalesDialog = (): void => {
    setIsSalesDialogOpen(true);
  };
  const closeSalesDialog = (): void => {
    setIsSalesDialogOpen(false);
  };

  const calculatorClicked = (): void => {
    if (isCalculatorOpen) {
      setIsCalculatorOpen(false);
    } else {
      setIsCalculatorOpen(true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const navJt = params.get('jt');
    const navplacement = params.get('placement');
    const jtSelection = params.get('jtSelection');

    if (isFirstLoad) {
      if (navJt !== null) {
        updateJt(navJt, isHorizontal, isHighVoltage);
      }
      if (navplacement !== null) {
        updatePlacement(navplacement);
      }
      if (jtSelection !== null) {
        updateJt('Micro', isHorizontal, isHighVoltage);
      }
      window.scrollTo(0, 0);
      setIsFirstLoad(false);
    }

    function UpdateScreenSize(): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    window.addEventListener('resize', UpdateScreenSize);
    UpdateScreenSize();
    return () => {
      window.removeEventListener('resize', UpdateScreenSize);
    };
  }, [location, isFirstLoad, isHorizontal, isHighVoltage]);

  const getSecondImage = (): string => {
    if (isHorizontal) {
      if (jtSelection === 'M90') {
        return MultiPump;
      } else if (placementSelection === 'COMBI') {
        return 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/install-positions/jt-combi.png';
      }
      return 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/install-positions/JT-bow-or-stern.jpg';
    }
    if (placementSelection === 'COMBI') {
      return 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/install-positions/jtv-combi.png';
    }
    return 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/install-positions/JTV-bow-or-stern.jpg';
  };

  const updateCurrentImage = (image: string): void => {
    setCurrentImage(image);
  };

  const updatePlacement = (placement: string): void => {
    const currentBoatDiagramRef = boatDiagramRef.current;

    if (currentBoatDiagramRef != null) {
      currentBoatDiagramRef.className = 'bounce-on';
    }

    setPlacementSelection(placement);

    if (currentBoatDiagramRef != null) {
      setTimeout(() => {
        currentBoatDiagramRef.className = 'bounce-off2';
      }, 300);
    }
  };

  const handleCloseCalculator = (): void => {
    if (!isCalculatorOpen) {
      setIsCalculatorOpen(true);
    } else {
      setIsCalculatorOpen(false);
      setIsFirstLoad(true);
    }
  };

  const handleOrientationChange = (event: any): void => {
    setIsHorizontal(event.target.value === 'Horizontal');
    updateJt(jtSelection, event.target.value === 'Horizontal', isHighVoltage);
  };

  const handleVoltageChange = (event: any): void => {
    setIsHighVoltage(event.target.value === 'High');
    updateJt(jtSelection, isHorizontal, event.target.value === 'High');
  };

  const handleShowLessClick = (): void => {
    setExpandInfoSection(false);
  };

  const handleShowMoreClick = (): void => {
    setExpandInfoSection(true);
  };

  const updateJt = (
    jt: string,
    isHoriz: boolean,
    isHighVolt: boolean
  ): void => {
    if (jt === '30') {
      setJtSpecsForSelection(JtThirtyThreeKwSpecs);

      if (isHoriz) {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JT30')[0].image
        );
      } else {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JTV30')[0].image
        );
      }
    }

    if (jt === '50') {
      if (isHighVolt) {
        setJtSpecsForSelection(JtFiftyFourtyEightVoltSpecs);
      } else {
        setJtSpecsForSelection(JtFiftyTwentyFourVoltSpecs);
      }

      if (isHoriz) {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JT50')[0].image
        );
      } else {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JTV50')[0].image
        );
      }
    }

    if (jt === '70') {
      if (isHighVolt) {
        setJtSpecsForSelection(JtSeventyFourtyEightVoltSpecs);
      } else {
        setJtSpecsForSelection(JtSeventyTwentyFourVoltSpecs);
      }

      if (isHoriz) {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JT70')[0].image
        );
      } else {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JTV70')[0].image
        );
      }
    }

    if (jt === '90' || jt === 'M90') {
      if (isHighVolt) {
        setJtSpecsForSelection(JtNinetyFourtyEightVoltSpecs);
      } else {
        setJtSpecsForSelection(JtNinetyTwentyFourVoltSpecs);
      }

      if (isHoriz) {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JT90')[0].image
        );
      } else {
        setActiveProductImage(
          JtWithProductImage.filter((x) => x.key === 'JTV90')[0].image
        );
      }
    }

    if (jt === 'Micro') {
      setJtSpecsForSelection(Micro);
      setActiveProductImage(
        JtWithProductImage.filter((x) => x.key === 'Micro')[0].image
      );
      if (placementSelection === 'COMBI') {
        setPlacementSelection('BOW');
      }
    }

    const currentPumpAssemblyRef = pumpAssemblyRef.current;
    const currentPumpAssemblyTitleRef = pumpAssemblyTitleRef.current;
    const currentPumpAssemblyInfoTableRef = pumpAssemblyInfoTableRef.current;

    if (
      currentPumpAssemblyRef != null &&
      currentPumpAssemblyTitleRef != null &&
      currentPumpAssemblyInfoTableRef != null
    ) {
      currentPumpAssemblyRef.className = 'bounce-on';
      currentPumpAssemblyTitleRef.className = 'bounce-on';
      currentPumpAssemblyInfoTableRef.className = 'bounce-on';
    }

    setJtSelection(jt);

    if (
      currentPumpAssemblyRef != null &&
      currentPumpAssemblyTitleRef != null &&
      currentPumpAssemblyInfoTableRef != null
    ) {
      setTimeout(() => {
        currentPumpAssemblyRef.className = 'bounce-off2';
        currentPumpAssemblyTitleRef.className = 'bounce-off2';
        currentPumpAssemblyInfoTableRef.className = 'bounce-off2';
      }, 300);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // Handle Screen Size Stuff
    function UpdateScreenSize(): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    window.addEventListener('resize', UpdateScreenSize);
    UpdateScreenSize();
    return () => {
      window.removeEventListener('resize', UpdateScreenSize);
    };
  }, []);

  function getDiagramImage(): string | undefined {
    return placementSelection === 'STERN'
      ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Stern.png'
      : placementSelection === 'BOW'
        ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Bow.png'
        : placementSelection === 'COMBI'
          ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Combi.png'
          : 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/double.jpeg';
  }

  function getPlacementImage(): string | undefined {
    if (jtSelection === 'M90') {
      return MultiPumpBoat;
    } else if (jtSelection !== 'Micro') {
      return placementSelection === 'STERN'
        ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Stern.png'
        : placementSelection === 'BOW'
          ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Bow.png'
          : placementSelection === 'COMBI'
            ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/Locations/Combi.png'
            : undefined;
    } else {
      return placementSelection === 'BOW'
        ? BowSystem
        : placementSelection === 'STERN'
          ? SternSystem
          : placementSelection === 'DOUBLE'
            ? DoubleSystem
            : EmptySystem;
    }
  }

  function getJtString(jtSelection: string): string {
    if (
      jtSelection === '30' ||
      jtSelection === '50' ||
      jtSelection === '70' ||
      jtSelection === '90'
    ) {
      return 'JT-' + jtSelection;
    }

    if (jtSelection === 'Micro') {
      return 'Micro';
    }

    if (jtSelection === 'M90') {
      return 'Multi-Pump';
    }

    return '';
  }

  function getPlacementString(placementSelection: string): string {
    if (
      placementSelection === 'BOW' ||
      placementSelection === 'STERN' ||
      placementSelection === 'DOUBLE'
    ) {
      return (
        placementSelection.charAt(0).toUpperCase() +
        placementSelection.slice(1).toLowerCase()
      );
    }

    if (placementSelection === 'COMBI') {
      return 'Combi';
    }

    return '';
  }

  function getApplicationText(): React.ReactNode {
    if (jtSelection === 'Micro') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          Our most integrated and compact design, for trailer boats, large ribs,
          or small vessels needing bow/stern only
        </h3>
      );
    }

    if (jtSelection === '30') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          20&apos; to 30&apos; keel boats, compact cruisers, and lighter
          displacement vessels up to 35&apos;
        </h3>
      );
    }

    if (jtSelection === '50') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          31&apos; to 40&apos; keel boats, small cruisers and pocket trawlers,
          light and planing hull vessles to 45&apos;
        </h3>
      );
    }

    if (jtSelection === '70') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          38&apos; to 48&apos; keel boats, cabin cruisers and trawlers,
          semi-planing and planing boats out to 50&apos;
        </h3>
      );
    }

    if (jtSelection === '90') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          45&apos; to 55&apos; keel boats, heavier cruising and house boats,
          catamarans w/ single hull installs out to 45&apos;
        </h3>
      );
    }

    if (jtSelection === 'M90') {
      return (
        <h3 style={{ marginBlock: '0', marginLeft: '25px' }}>
          55&apos; keel boats and beyond, large vessels of any kind
        </h3>
      );
    }
    return <h1></h1>;
  }

  return (
    <>
      <div style={{ background: '#e7e7e7' }}>
        <div className="container">
          <Breadcrumb
            items={[
              { text: 'Home', to: '/' },
              { text: 'Jet Thruster Systems', to: '/systems' },
              {
                text:
                  getJtString(jtSelection) +
                  ' - ' +
                  getPlacementString(placementSelection),
                to: '#'
              }
            ]}
            title={`Jet Thruster: ${getJtString(
              jtSelection
            )} - ${getPlacementString(placementSelection)}`}
          />
        </div>
      </div>

      <div className="page-content-container container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jet Thruster America - System Builder</title>
          <link
            rel="canonical"
            href="http://jetthrusteramerica.com/systems/builder"
          />
          <meta
            name="description"
            content="To help you find the perfect Jet Thruster System, we have assembled a System Builder page. Here you will be able to see our different JT-Series pumps and how each of them differ."
          />
        </Helmet>

        <ContactSalesDialog
          isSalesDialogOpen={isSalesDialogOpen}
          closeSalesDialog={closeSalesDialog}
        />

        <ThrusterCalculator
          isCalculatorOpen={isCalculatorOpen}
          closeCalculator={handleCloseCalculator}
        />

        {/* header section */}
        {/* <div style = {{
          display: 'flex',
          backgroundColor: '#F1F1F1',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          borderBottom: '2px solid darkgrey',
          marginBottom: '25px'
        }}> */}
        {/* <h1 style = {{ marginBlock: '0', marginLeft: '25px', marginTop: '40px', marginBottom: '20px' }}>
            Jet Thruster: {getJtString(jtSelection)} - {getPlacementString(placementSelection)}
          </h1> */}

        {/* <Breadcrumb items={[
            { text: 'Home', to: '/' },
            { text: 'Jet Thruster Systems', to: '/systems' },
            { text: getJtString(jtSelection) + ' - ' + getPlacementString(placementSelection), to: '#' }
          ]} title={`Jet Thruster: ${getJtString(jtSelection)} - ${getPlacementString(placementSelection)}`}/> */}
        {/* </div> */}

        {/* secondary header section */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: '5px solid hsl(345, 96%, 42%)',
            maxWidth: '1000px',
            marginBottom: '15px',
            paddingBottom: '15px',
            flexWrap: 'wrap'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '15px 25px',
              flex: 3
            }}
          >
            <h3
              style={{
                marginBlockEnd: '0px',
                marginBlockStart: '0px',
                marginBottom: '10px'
              }}
            >
              System Builder
            </h3>
            <small style={{ marginBottom: '10px' }}>
              The flexibility and scalabiliity of Jet Thruster offers
              unparalleled opportunities.
            </small>
            <small style={{ color: '#3887FF', fontSize: '.75rem' }}>
              Select Details Below
            </small>
          </div>

          <div style={{ flex: 1 }}></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0px 25px',
              flex: 2
            }}
          >
            <h3
              style={{
                marginBlockEnd: '0px',
                marginBlockStart: '0px',
                marginBottom: '10px'
              }}
            >
              Need Help Deciding?
            </h3>
            <small style={{ color: '#3887FF', marginBottom: '15px' }}>
              Use the Thruster Calculator
            </small>
            <button
              type="button"
              className="get-quote-button"
              id="nav-bar-get-quote"
              onClick={calculatorClicked}
              style={{ boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)' }}
            >
              <h5
                id="nav-bar-get-quote"
                style={{
                  marginBlockEnd: '0px',
                  marginBlockStart: '0px',
                  color: 'white',
                  fontWeight: 'normal',
                  fontSize: '18px'
                }}
              >
                Calculator &nbsp; ›
              </h5>
            </button>
          </div>
        </div>

        {/* First Row */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            marginLeft: '5px'
          }}
        >
          {/* Pick Placement */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              flex: 1
            }}
          >
            {/* section header/description */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                width: '100%',
                marginLeft: '15px'
              }}
            >
              <h5
                style={{
                  marginBlockEnd: '0em',
                  marginBlockStart: '0em',
                  textAlign: 'left',
                  fontSize: '1.3rem'
                }}
              >
                Layouts
              </h5>
              <small
                style={{
                  color: '#5296FF',
                  marginBlockEnd: '0em',
                  marginBlockStart: '0em',
                  textAlign: 'left',
                  minWidth: '6.5rem',
                  fontSize: '1.1rem',
                  marginBottom: '1.2rem'
                }}
              >
                Choose a Layout
              </small>
            </div>
            {/* Options */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textAlign: 'left'
              }}
            >
              <button
                id="system-builder-bow"
                className={
                  placementSelection === 'BOW'
                    ? 'placement-button-clicked'
                    : 'placement-button'
                }
                type="button"
                onClick={() => {
                  updatePlacement('BOW');
                }}
              >
                Bow{' '}
                {placementSelection === 'BOW' && (
                  <img
                    src={ChevronRight}
                    width="6px"
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </button>
              <button
                id="system-builder-stern"
                className={
                  placementSelection === 'STERN'
                    ? 'placement-button-clicked'
                    : 'placement-button'
                }
                type="button"
                onClick={() => {
                  updatePlacement('STERN');
                }}
              >
                Stern{' '}
                {placementSelection === 'STERN' && (
                  <img
                    src={ChevronRight}
                    width="6px"
                    style={{ marginLeft: '5px' }}
                  />
                )}
              </button>
              <button
                id="system-builder-combi"
                className={
                  placementSelection === 'COMBI'
                    ? 'placement-button-clicked'
                    : jtSelection === 'Micro'
                      ? 'placement-button-disabled'
                      : 'placement-button'
                }
                type="button"
                onClick={() => {
                  updatePlacement('COMBI');
                }}
              >
                Bow and Stern{' '}
                {placementSelection === 'COMBI' && (
                  <img
                    src={ChevronRight}
                    width="6px"
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </button>
              <button
                id="system-builder-double"
                className={
                  placementSelection === 'DOUBLE'
                    ? 'placement-button-clicked'
                    : 'placement-button'
                }
                type="button"
                onClick={() => {
                  updatePlacement('DOUBLE');
                }}
              >
                Double{' '}
                {placementSelection === 'DOUBLE' && (
                  <img
                    src={ChevronRight}
                    width="6px"
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </button>
            </div>
          </div>

          {/* Images */}
          <div
          className='system-builder-image-container'
            // style={{
            //   display: 'flex',
            //   flexDirection: 'row',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            //   flex: 3,
            //   marginLeft: '0px',
            //   height: '272px'
            // }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <small
                style={{
                  color: '#5296FF',
                  textAlign: 'left',
                  width: '100%',
                  transform:
                    jtSelection === 'Micro' ? 'none' : 'translateY(50px)'
                }}
              >
                Diagram View
              </small>
              <img
                src={
                  jtSelection === 'Micro'
                    ? 'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/FullMicro.png'
                    : getSecondImage()
                }
                alt="Pump Install Diagram for Orientation selected"
                height={jtSelection === 'Micro' ? 175 : 280}
                draggable="false"
                className="bounce-off2"
              />
            </div>
            
              <div
              className='system-builder-image-container'
                // style={{
                //   display: 'flex',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   flexDirection: 'column',
                //   marginLeft: '25px',
                //   paddingLeft: '25px'
                // }}
              >
                <small
                  style={{
                    color: '#5296FF',
                    textAlign: 'left',
                    width: '100%',
                    transform:
                      jtSelection === 'Micro' ? 'none' : 'translateY(50px)'
                  }}
                >
                  Install View
                </small>
                <img
                  src={
                    getPlacementImage() === undefined
                      ? DoubleImage
                      : getPlacementImage()
                  }
                  alt="Selected Thruster Layout"
                  draggable="false"
                  className="installed-image"
                  height={jtSelection === 'Micro' ? 190 : 280}
                />
              </div>
            
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
            width: '80%'
          }}
        >
          <h4 style={{ marginBlock: '0', textAlign: 'left', width: '100%' }}>
            Pumps
          </h4>
          <small
            style={{
              color: '#5296FF',
              textAlign: 'left',
              width: '100%',
              marginBlock: '0'
            }}
          >
            Choose a pump
          </small>
        </div>
        {/* Row 2 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '15px',
            marginBottom: '1rem',
            width: '100%'
          }}
        >
          <button
            className={
              jtSelection === 'Micro'
                ? 'jt-option-first-active'
                : 'jt-option-first'
            }
            onClick={() => {
              updateJt('Micro', isHorizontal, isHighVoltage);
            }}
            id="system-builder-micro"
          >
            Micro{' '}
            {jtSelection === 'Micro' ? (
              <img src={ChevronUp} height="10px" />
            ) : (
              ''
            )}
          </button>
          <button
            className={jtSelection === '30' ? 'jt-option-active' : 'jt-option'}
            onClick={() => {
              updateJt('30', isHorizontal, isHighVoltage);
            }}
            id="system-builder-jt-30"
          >
            JT-30{' '}
            {jtSelection === '30' ? <img src={ChevronUp} height="10px" /> : ''}
          </button>
          <button
            className={jtSelection === '50' ? 'jt-option-active' : 'jt-option'}
            onClick={() => {
              updateJt('50', isHorizontal, isHighVoltage);
            }}
            id="system-builder-jt-50"
          >
            JT-50{' '}
            {jtSelection === '50' ? <img src={ChevronUp} height="10px" /> : ''}
          </button>

          <button
            className={jtSelection === '70' ? 'jt-option-active' : 'jt-option'}
            onClick={() => {
              updateJt('70', isHorizontal, isHighVoltage);
            }}
            id="system-builder-jt-70"
          >
            JT-70{' '}
            {jtSelection === '70' ? <img src={ChevronUp} height="10px" /> : ''}
          </button>
          <div style={{ display: 'flex' }}>
            <button
              className={
                jtSelection === '90' ? 'jt-option-active' : 'jt-option'
              }
              onClick={() => {
                updateJt('90', isHorizontal, isHighVoltage);
              }}
              id="system-builder-jt-90"
            >
              JT-90{' '}
              {jtSelection === '90' ? (
                <img src={ChevronUp} height="10px" />
              ) : (
                ''
              )}
            </button>
            <button
              className={
                jtSelection === 'M90' ? 'jt-option-active' : 'jt-option'
              }
              onClick={() => {
                updateJt('M90', isHorizontal, isHighVoltage);
              }}
              id="system-builder-multi-pump"
            >
              Multi-Pump{' '}
              {jtSelection === 'M90' ? (
                <img src={ChevronUp} height="10px" />
              ) : (
                ''
              )}
            </button>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
            marginLeft: '5px'
          }}
        >
          <h3 style={{ marginBlock: '0', color: 'grey', marginLeft: '60px' }}>
            Vessel Applications:
          </h3>
          {getApplicationText()}
        </div>

        <hr
          style={{
            height: '35px',
            width: '100%',
            backgroundColor: '#F1F1F1',
            border: '0'
          }}
        />

        <h3
          style={{
            marginBlock: '0',
            width: '80%',
            textAlign: 'left',
            marginBottom: '1rem'
          }}
        >
          Pump Options
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {jtSelection !== 'Micro' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginLeft: '25px'
              }}
            >
              <small style={{ color: '#5296FF', marginBlock: '0' }}>
                Choose Orientation
              </small>
              <FormControl component="fieldset" style={{}}>
                <RadioGroup
                  aria-label="orientation"
                  name="orientation"
                  value={isHorizontal ? 'Horizontal' : 'Vertical'}
                  onChange={handleOrientationChange}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FormControlLabel
                      value="Horizontal"
                      control={<Radio id="system-builder-horizontal" />}
                      label="Horizontal"
                    />
                    <FormControlLabel
                      value="Vertical"
                      control={<Radio id="system-builder-vertical" />}
                      label="Vertical"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </div>
          )}
          {jtSelection !== '30' && jtSelection !== 'Micro' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginLeft: '25px'
              }}
            >
              <small style={{ color: '#5296FF', marginBlock: '0' }}>
                Choose Voltage
              </small>
              <FormControl component="fieldset" style={{}}>
                <RadioGroup
                  aria-label="voltage"
                  name="voltage"
                  value={isHighVoltage ? 'High' : 'Low'}
                  onChange={handleVoltageChange}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    <FormControlLabel
                      value="Low"
                      control={<Radio id="system-builder-low-voltage" />}
                      label="24V Motor"
                    />
                    <FormControlLabel
                      value="High"
                      control={<Radio id="system-builder-high-voltage" />}
                      label="48V Motor"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* Left Side */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              userSelect: 'none'
            }}
          >
            <div
              ref={pumpAssemblyInfoTableRef}
              style={{ minWidth: '250px', maxWidth: '600px' }}
            >
              <TableContainer
                component={Paper}
                sx={{ minWidth: '250px', maxWidth: '600px' }}
              >
                <Table
                  sx={{ minWidth: '250px', maxWidth: '600px' }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow className="info-table-row">
                      <TableCell width={160}>Force</TableCell>
                      <TableCell>{jtSpecsForSelection.force}</TableCell>
                    </TableRow>
                    {isHorizontal && (
                      <TableRow className="info-table-row">
                        <TableCell>Pump Dimensions</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.horizontalDimensions} (H)
                        </TableCell>
                      </TableRow>
                    )}
                    {!isHorizontal && (
                      <TableRow className="info-table-row">
                        <TableCell>Pump Dimensions</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.verticalDimensions} (V)
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow className="info-table-row">
                      <TableCell>Run Voltage DC</TableCell>
                      <TableCell>
                        {jtSpecsForSelection.runVoltageDC}
                        <Link to="/systems/components#voltage" target="_blank">
                          <FontAwesomeIcon
                            className="component-info-icon"
                            icon={faBolt}
                            size="lg"
                            color="#292a59"
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow className="info-table-row">
                      <TableCell>Wattage</TableCell>
                      <TableCell>{jtSpecsForSelection.wattage}</TableCell>
                    </TableRow>
                    <TableRow className="info-table-row">
                      <TableCell>Current</TableCell>
                      <TableCell>{jtSpecsForSelection.current}</TableCell>
                    </TableRow>

                    {jtSelection !== '30' && (
                      <TableRow className="info-table-row">
                        <TableCell>Optional Battery/Charge Voltage</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.optionalBatteryChargeVoltage}
                          <Link
                            to="/systems/components#voltage"
                            target="_blank"
                          >
                            <FontAwesomeIcon
                              className="component-info-icon"
                              icon={faBolt}
                              size="lg"
                              color="#292a59"
                            />
                          </Link>
                          <br />
                          <small>
                            *P/S option allows use of <br /> pump batteries at
                            this voltage
                          </small>
                        </TableCell>
                      </TableRow>
                    )}

                    {(expandInfoSection || jtSelection === '30') && (
                      <TableRow className="info-table-row">
                        <TableCell>Fuse Rating</TableCell>
                        <TableCell>{jtSpecsForSelection.fuseRating}</TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Minimum Battery Capacity</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.minimumBatteryCapacity}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Recommended Battery</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.recommendedBattery}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Recommended Battery Cable</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.recommendedBatteryCable}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Water Inlet Size</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.waterInletSize}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Discharge Nozzle Outlet Sizes</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.dischargeNozzleOutletSizes}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Pressure Hose Diameter</TableCell>
                        <TableCell>
                          {jtSpecsForSelection.pressureHoseDiameter}
                        </TableCell>
                      </TableRow>
                    )}
                    {expandInfoSection && (
                      <TableRow className="info-table-row">
                        <TableCell>Weight</TableCell>
                        <TableCell>{jtSpecsForSelection.weight}</TableCell>
                      </TableRow>
                    )}

                    <TableRow className="info-table-row info-table-ending-row">
                      {expandInfoSection && (
                        <TableCell
                          colSpan={2}
                          onClick={() => {
                            handleShowLessClick();
                          }}
                        >
                          <span
                            className="info-table-ending-cell"
                            id="system-builder-show-less"
                          >
                            <i id="system-builder-show-less">SHOW LESS</i>
                          </span>
                          <span
                            className="info-table-ending-cell"
                            id="system-builder-show-less"
                          >
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              size="lg"
                              color="white"
                              id="system-builder-show-less"
                            />
                          </span>
                        </TableCell>
                      )}
                      {!expandInfoSection && (
                        <TableCell
                          colSpan={2}
                          onClick={() => {
                            handleShowMoreClick();
                          }}
                          style={{ padding: '5px' }}
                        >
                          <span
                            className="info-table-ending-cell"
                            id="system-builder-show-more"
                          >
                            <i id="system-builder-show-more">SHOW MORE</i>
                          </span>
                          <span
                            className="info-table-ending-cell"
                            id="system-builder-show-more"
                          >
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              size="lg"
                              color="white"
                              id="system-builder-show-more"
                            />
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          {/* Right side */}
          <div
            style={{
              marginLeft: screenSize === ScreenSize.SMALL ? '0px' : '50px',
              marginTop: screenSize === ScreenSize.SMALL ? '45px' : '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '275px'
              }}
            >
              <img
                ref={pumpAssemblyRef}
                src={activeProductImage}
                alt="Selected Pump Assembly"
                style={{
                  maxWidth: '300px',
                  minWidth: '250px',
                  maxHeight: '247.5px'
                }}
                draggable="false"
                className="bounce-off2"
              />
            </div>
          </div>
        </div>

        <hr
          style={{
            height: '35px',
            width: '100%',
            backgroundColor: '#F1F1F1',
            border: '0'
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: window.innerWidth > 550 ? 'row' : 'column',
            alignItems: window.innerWidth > 550 ? 'flex-start' : 'center',
            textAlign: window.innerWidth > 550 ? 'left' : 'center',
            flexWrap: 'wrap',
            width: '95%',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              margin: '10px'
            }}
          >
            <h3 style={{ marginBlockEnd: '10px' }}>Basic System Options</h3>
            <div style={{ marginLeft: '1rem' }}>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Nozzle Angles
              </h5>
              <small>
                Discharge nozzles are available in angles from 0&deg; to 65&deg;
              </small>
              <br />
              <Link
                to={'/downloads?query=nozzle angle'}
                style={{ all: 'unset' }}
              >
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>DOWNLOAD NOZZLE SELECTION GUIDE</u>
                </small>
              </Link>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Control Panels
              </h5>
              <small>
                Push Button and Joystick control panel options are available
              </small>
              <br />
              <Link
                to="/systems/components#control-panels"
                style={{ all: 'unset' }}
              >
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>VIEW PANEL OPTIONS</u>
                </small>
              </Link>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Nozzle Types
              </h5>
              <small>
                Composite (Standard) <br /> Stainless Steel <br /> Aluminum
                Weldable
              </small>
              <br />
              <Link to="/systems/components#nozzles" style={{ all: 'unset' }}>
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>READ MORE</u>
                </small>
              </Link>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              margin: '10px',
              borderTop: window.innerWidth > 550 ? '0' : '3px solid grey',
              alignContent: window.innerWidth > 550 ? 'inherit' : 'center',
              textAlign: window.innerWidth > 550 ? 'inherit' : 'center'
            }}
          >
            <h3 style={{ marginBlockEnd: '10px' }}>Available Accessories</h3>
            <div style={{ marginLeft: '1rem' }}>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                User Interface
              </h5>
              <small>
                Wireless Remote <br /> Second Helm Control <br /> Existing
                Thruster Integration <br /> Extended Run Timers
              </small>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Charging
              </h5>
              <small>
                Battery Switches <br /> Step Up DC Chargers <br />{' '}
                Parallel/Series Switch
                <br /> Smart Battery Isolators
              </small>
              <br />
              <Link to="/systems/components#batteries" style={{ all: 'unset' }}>
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>READ MORE</u>
                </small>
              </Link>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              margin: '10px',
              borderTop: window.innerWidth > 550 ? '0' : '3px solid grey'
            }}
          >
            <h3 style={{ marginBlockEnd: '10px' }}>Special Configurations</h3>
            <div style={{ marginLeft: '1rem' }}>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Optional Power Choices
              </h5>
              <small>
                DC to AC Induction <br /> Proportional Control <br /> Hydraulic
                Pump Drive
              </small>
              <br />
              <Link to="/systems/components#pumps" style={{ all: 'unset' }}>
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>READ MORE</u>
                </small>
              </Link>
              <h5 style={{ marginBlock: '4px', marginTop: '10px' }}>
                Thrust Vectors
              </h5>
              <small>
                Forward Thrust <br /> Reverse Thrust <br /> 45&deg; Thrust{' '}
              </small>
              <br />
              <div onClick={openSalesDialog}>
                <small style={{ color: '#3887FF', cursor: 'pointer' }}>
                  <u>Contact Sales for more details</u>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: '#F1F1F1',
            display: 'flex',
            flexDirection: window.innerWidth > 970 ? 'row' : 'column',
            width: '100%',
            paddingTop: '25px',
            paddingBottom: '25px',
            flexWrap: window.innerWidth > 970 ? 'initial' : 'wrap',
            alignItems: window.innerWidth > 970 ? 'inherit' : 'center',
            textAlign: window.innerWidth > 970 ? 'inherit' : 'center'
          }}
        >
          <div style={{ margin: '10px' }}>
            <h1 style={{ marginBlock: '0' }}>Ready to Purchase?</h1>
            <h2 style={{ marginBlock: '0', color: '#3887FF' }}>
              Get a Quote Today
            </h2>
          </div>
          <div style={{ margin: '10px' }} id="nav-bar-get-quote">
            <button
              type="button"
              className="get-quote-button"
              id="nav-bar-get-quote"
              onClick={openSalesDialog}
              style={{
                boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)'
              }}
            >
              <h5
                id="nav-bar-get-quote"
                style={{
                  marginBlockEnd: '0px',
                  marginBlockStart: '0px',
                  color: 'white',
                  fontWeight: 'normal',
                  fontSize: '18px'
                }}
              >
                Get a Quote &nbsp; ›
              </h5>
            </button>
          </div>
          <div style={{ margin: '10px' }}>
            <h2 style={{ marginBlock: '0' }}>or</h2>
          </div>
          <div style={{ margin: '10px' }}>
            <h1 style={{ marginBlock: '0' }}>Call our Sales Team</h1>
            <h2
              style={{ marginBlock: '0', color: '#3887FF', cursor: 'pointer' }}
            >
              <a href="tel:855-584-7878" style={{ all: 'unset' }}>
                855-584-7878
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default SystemBuilder;
