import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement); // Use type assertion

root.render(
  <BrowserRouter basename={baseUrl ?? '/'}>
    <App />
  </BrowserRouter>
);
