export class SalesFormModel {
  // typeOfBoat: string;
  boatLength: string;
  boatName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerQuestionOrComment: string;
  imageUrl: string;
  imageFile: any;
  recaptchaValue: string;

  constructor (
    // typeOfBoat: string,
    boatLength: string,
    boatName: string,
    customerEmail: string,
    customerPhoneNumber: string,
    customerQuestionOrComment: string,
    imageUrl: string,
    imageFile: any,
    recaptchaValue: string
  ) {
    // this.typeOfBoat = typeOfBoat;
    this.boatLength = boatLength;
    this.boatName = boatName;
    this.customerEmail = customerEmail;
    this.customerPhoneNumber = customerPhoneNumber;
    this.customerQuestionOrComment = customerQuestionOrComment;
    this.imageUrl = imageUrl;
    this.imageFile = imageFile;
    this.recaptchaValue = recaptchaValue;
  }
}
