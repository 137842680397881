import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import HouseIcon from '@mui/icons-material/House';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Link } from 'react-router-dom';
import ContactSalesDialog from './ContactComponents/ContactSalesDialog';
import ContactSupportDialog from './ContactComponents/ContactSupportDialog';

// [
//     {text: 'Home', to: '/'},
//     {text: 'Systems', to: '/systems'}
// ]

interface BreadcrumbItem {
  text: string;
  to: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  title: string;
}

function handleClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
): void {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function Breadcrumb({
  items,
  title
}: BreadcrumbProps): React.ReactElement {
  const [isSalesDialogOpen, setIsSalesDialogOpen] = React.useState(false);
  const [isSupportDialogOpen, setIsSupportDialogOpen] = React.useState(false);

  const openSalesDialog = (): void => {
    setIsSalesDialogOpen(true);
  };
  const openSupportDialog = (): void => {
    setIsSupportDialogOpen(true);
  };
  const closeSalesDialog = (): void => {
    setIsSalesDialogOpen(false);
  };
  const closeSupportDialog = (): void => {
    setIsSupportDialogOpen(false);
  };
  return (
    <div
      role="presentation"
      onClick={handleClick}
      style={{
        background: 'transparent',
        paddingTop: '.5rem',
        paddingBottom: '1rem',
        width: '100%'
      }}
    >
      <ContactSalesDialog
        isSalesDialogOpen={isSalesDialogOpen}
        closeSalesDialog={closeSalesDialog}
      />
      <ContactSupportDialog
        isSupportDialogOpen={isSupportDialogOpen}
        closeSupportDialog={closeSupportDialog}
      />
      <div className="breadcrumb">
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            backgroundColor: 'transparent',
            color: '#858585',
            marginLeft: '1rem',
            height: '25px'
          }}
        >
          {items.map((item, index) => {
            const linkColor = item.text === 'Home' ? '#03BDE7' : 'inherit';
            return (
              <Link
                key={index}
                color="inherit"
                to={item.to}
                style={{
                  fontSize: '14px',
                  color: linkColor,
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {item.text === 'Home' && (
                  <HouseIcon
                    sx={{ mr: 0.5, fontSize: '18px' }}
                    fontSize="inherit"
                  />
                )}
                {item.text}
              </Link>
            );
          })}
          {/* <Link
        //   underline="hover"
        //   sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          to="/"
        >
          <HouseIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          MUI
        </Link> */}
          {/* <Link
        //   underline="hover"
        //   sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          to="/material-ui/getting-started/installation/"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Core
        </Link> */}
          {/* <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Breadcrumb
        </Typography> */}
        </Breadcrumbs>
        <div className="breadcrumb-text">Powerful, Modular, Scalable</div>
      </div>

      <div
        className="nav-bar-breadcrumb"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1
          style={{
            marginBlock: '0',
            marginLeft: '25px',
            marginTop: '0px'
            // marginBottom: '20px'
          }}
        >
          {title}
        </h1>

        <div className=''>
          <button
            type="button"
            className="get-quote-button breadcrumb-buttons"
            id="nav-bar-get-quote"
            style={{
              boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)',
              marginRight: '30px'
            }}
            onClick={openSupportDialog}
          >
            <h5
              id="nav-bar-get-quote"
              style={{
                marginBlockEnd: '0px',
                marginBlockStart: '0px',
                color: 'white',
                fontWeight: 'normal',
                fontSize: '18px'
              }}
            >
              Existing Customer &nbsp; ›
            </h5>
          </button>

          <button
            type="button"
            className="get-quote-button"
            id="nav-bar-get-quote"
            style={{
              boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)'
              // marginRight: '80px'
            }}
            onClick={openSalesDialog}
          >
            <h5
              id="nav-bar-get-quote"
              style={{
                marginBlockEnd: '0px',
                marginBlockStart: '0px',
                color: 'white',
                fontWeight: 'normal',
                fontSize: '18px'
              }}
            >
              New Customer &nbsp; ›
            </h5>
          </button>
        </div>
      </div>
    </div>
  );
}
