import React from 'react';
import './MultiImageDisplay.css';
import type { SlideshowProps } from '../../Models/SlideItem';

const MultiImageDisplay: React.FC<SlideshowProps> = ({ slides }): React.ReactElement => {
  const renderSlides = (): React.ReactElement[] => {
    return slides.map((slide, index) => {
      return (
        <div key={index} className='multi-slideshow-slide'>
          <img style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '5px'
          }} src={slide.imageUrl} className='multi-slideshow-image ' alt={slide.title} />
          <div className='multi-slideshow-text'>{slide.title}</div>
        </div>
      );
    });
  };

  return (
    <div className='multi-slideshow'>
      {renderSlides()}
    </div>
  );
};

export default MultiImageDisplay;
