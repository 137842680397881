import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import BoatLengthIcon from '../Images/CalculatorBoatLengthSVG.svg';
import BoatHeightIcon from '../Images/CalculatorBoatHeight2SVG.svg';
import BoatWindIcon from '../Images/CalculatorBoatWindSVG.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import './ThrusterCalculator.css';
import '../SharedStyles.css';
import { Link } from 'react-router-dom';

interface DialogProps {
  isCalculatorOpen: boolean;
  closeCalculator: () => void;
}

const ThrusterCalculator: React.FC<DialogProps> = ({ isCalculatorOpen, closeCalculator }) => {
  const defaultBoatLength: string = '';
  const defaultBoatAboveWaterHeight: string = '';
  const defaultWindCapture: string = '';
  const defaultIsSubmitting = false;

  const [boatLength, setBoatLength] = React.useState(defaultBoatLength);
  const [boatAboveWaterHeight, setBoatAboveWaterHeight] = React.useState(defaultBoatAboveWaterHeight);
  const [windCapture, setWindCapture] = React.useState<string | number>(defaultWindCapture);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(defaultIsSubmitting);
  const [calculatorResults, setCalculatorResults] = React.useState<number | null>(null);
  const [currentThrusterReccomendation, setCurrentThrusterReccomendation] = React.useState<string>('');
  const [currentThrusterNumber, setCurrentThrusterNumber] = React.useState<number>(30);

  const calculateMinStagnationPressure = (): number => {
    const boatLengthNumber = Number(boatLength) / 3.28083989;
    const boatAboveWaterHeightNumber = Number(boatAboveWaterHeight) / 3.28083989;
    let windCaptureNumber = Number(windCapture);

    windCaptureNumber =
      windCaptureNumber === 5
        ? 4.5
        : windCaptureNumber === 6
          ? 7.5
          : 12;

    let minStagnationPressure = (boatLengthNumber * boatAboveWaterHeightNumber) * 0.5 * 0.75 * windCaptureNumber;
    minStagnationPressure = Math.round(minStagnationPressure * 100) / 100;

    setCurrentThrusterReccomendation(
      minStagnationPressure < 33
        ? 'JT-30'
        : minStagnationPressure < 55
          ? 'JT-50'
          : minStagnationPressure < 77
            ? 'JT-70'
            : minStagnationPressure < 99
              ? 'JT-90'
              : 'Multiple pumps'
    );

    setCurrentThrusterNumber(
      minStagnationPressure < 33
        ? 30
        : minStagnationPressure < 55
          ? 50
          : minStagnationPressure < 77
            ? 70
            : 90);
    return minStagnationPressure;
  };

  const handleClose = (): void => {
    if (!isSubmitting) {
      resetToDefaults();
    }
  };

  // Function to reset all state variables to their default values
  const resetToDefaults = (): void => {
    setBoatLength(defaultBoatLength);
    setBoatAboveWaterHeight(defaultBoatAboveWaterHeight);
    setWindCapture(defaultWindCapture);
    setIsSubmitting(defaultIsSubmitting);
    closeCalculator();
  };

  const closeDialog = (): void => {
    closeCalculator();
  };

  const renderCalculatorResults = (): React.ReactElement => {
    if (calculatorResults === null && (boatLength !== '' || boatAboveWaterHeight !== '' || windCapture !== '')) {
      return (
        <FontAwesomeIcon icon={faEllipsis} className='fas fas-elipsis' beatFade size='xl' />
      );
    };
    if (calculatorResults === null && boatLength === '' && boatAboveWaterHeight === '' && windCapture === '') {
      return (
        <div>
          <h5 style = {{ marginBlock: '0px', color: 'grey', textAlign: 'left', marginLeft: '10px', marginBottom: '10px' }}>How does the calculator work?</h5>
          <p style = {{ marginBlock: '0px', color: 'grey', textAlign: 'left', marginTop: '5px', marginLeft: '20px', marginRight: '20px', fontSize: '.65rem' }}>
            Displacement and Current effect Thrust Control,
            but Wind Capture is the primary counter force to Thrust.
          </p>
          <p style = {{ marginBlock: '0px', color: '#3887FF', textAlign: 'left', marginTop: '5px', marginLeft: '20px', fontSize: '.65rem' }}>
            Enter Parameters below to estimate thrust required for the given conditions.
          </p>
        </div>
      );
    };
    if (calculatorResults !== null && calculatorResults <= 33) {
      return (
        <>
        <h1 style = {{ fontSize: '1.75rem', marginBlock: '0' }}>{calculatorResults} KGF</h1>
          <p style = {{ marginBlock: '0', marginTop: '10px' }}>
            <Link to={'/systems/builder?jt=30'} className='calculator-link' onClick={closeDialog}>
              <strong style={{ textDecoration: 'underline' }}>JT-30</strong>
            </Link>
            {' or '}
            <Link to={'/systems/micro'} className='calculator-link' onClick={closeDialog}>
              <strong style={{ textDecoration: 'underline' }}>Micro</strong>
            </Link>
            {' recommended'}
          </p>
        </>
      );
    }
    return (
      <>
        <h1 style = {{ fontSize: '1.75rem', marginBlock: '0' }}>{calculatorResults} KGF</h1>
        <p style = {{ marginBlock: '0', marginTop: '10px' }}>
          <Link to={'/systems/builder?jt=' + currentThrusterNumber.toString()} className='calculator-link' onClick={closeDialog}>
            <strong style={{ textDecoration: 'underline' }}>
              {currentThrusterReccomendation}
            </strong>
          </Link>
          {' recommended'}
        </p>
      </>
    );
  };

  React.useEffect(() => {
    if (boatLength !== '' && boatAboveWaterHeight !== '' && windCapture !== '') {
      setCalculatorResults(calculateMinStagnationPressure());
    } else {
      setCalculatorResults(null);
    }
  }, [boatLength, boatAboveWaterHeight, windCapture]);

  return (
    <Modal open={isCalculatorOpen} onClose={handleClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          minWidth: '280px',
          width: '50vw',
          maxWidth: '485px',
          textAlign: 'center',
          minHeight: '275px',
          maxHeight: '90vh',
          overflowY: 'auto',
          borderRadius: '30px'
        }}
      >
        <div className='calculator-results'>
        <h1 style = {{ marginBlock: '0px', fontSize: '1.75rem' }}>Jet Thruster Calculator</h1>
        </div>
        <div style = {{
          border: '1px solid grey',
          borderRadius: '15px',
          marginTop: '10px',
          height: '125px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {renderCalculatorResults()}
        </div>
        <div className='calculator'>
          <div className='calculator-length calculator-section'>
            <img className='calculator-length-icon' src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/calculator/boat-length.png' alt='Boat Length Example' />
            <div className='calculator-inputs'>
              <h5><strong>Vessel Length (in ft.)</strong></h5>
              <p>Length over all, including extensions</p>
              <input type='number' value={boatLength} onChange={(e) => { setBoatLength(e.target.value); }} />
            </div>
          </div>
          <div className='calculator-height calculator-section'>
            <img className='calculator-height-icon' src='https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/calculator/boat-height.png' alt='Height Above Water Line Example' />
            <div className='calculator-inputs'>
              <h5><strong>Height Above Water Line  (in ft.)</strong></h5>
              <p>Average the cross section of rigging and superstructure</p>
              <div style = {{ display: 'flex', flexDirection: window.innerWidth > 700 ? 'row' : 'column', alignItems: window.innerWidth > 700 ? 'flex-start' : 'center' }}>
                <input className='exact-calc-input' type='number' value={boatAboveWaterHeight} onChange={(e) => { setBoatAboveWaterHeight(e.target.value); }} />
                {/* <small style = {{ color: 'grey', fontSize: '9pt', textAlign: 'center', marginLeft: '1px', marginRight: '10px', justifyContent: 'center' }}>
                  (Average hull, rigging, and superstructure)
                </small> */}

              </div>
            </div>
          </div>
          <div className='calculator-wind calculator-section'>
            <img className='calculator-wind-icon' src={BoatWindIcon} alt='Wind Capture Example' />
            <div className='calculator-inputs'>
              <h5><strong>Wind Capture</strong></h5>
              <p>Anticipated Conditions in Knots/Beaufort Scale</p>
              <div className='calculator-wind-options'>
                <div className={windCapture === 5 ? 'calculator-wind-option active' : 'calculator-wind-option'}
                onClick={() => { setWindCapture(5); }}>
                  <h6>5 BFT</h6>
                  <p>Fresh Breeze</p>
                  <p>[17-21kn]</p>
                </div>
                <div className={windCapture === 6 ? 'calculator-wind-option active' : 'calculator-wind-option'}
                onClick={() => { setWindCapture(6); }}>
                  <h6>6 BFT</h6>
                  <p>Strong Breeze</p>
                  <p>[22-27kn]</p>
                </div>
                <div className={windCapture === 7 ? 'calculator-wind-option active' : 'calculator-wind-option'}
                onClick={() => { setWindCapture(7); }}>
                  <h6>7 BFT</h6>
                  <p>High Wind</p>
                  <p>[28-33kn]</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p style = {{ color: 'grey', fontSize: '.75rem', marginBlock: '0px', marginTop: window.innerWidth > 700 ? '10px' : '15px' }}>
              <b>Note: </b> Small consistent forces move large vessels, but strong cross currents may require greater thrust for any vessel.
            </p>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ThrusterCalculator;
