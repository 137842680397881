import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import SiteLoading from './Components/SiteLoading';

function App(): React.ReactElement {
  React.useEffect(() => {
    document.body.style.overflowY = 'scroll';
    document.body.style.overflowX = 'hidden';
  }, []);

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <Routes>
          {AppRoutes.map((route: any, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <React.Suspense fallback={<SiteLoading />}>
                    {route.element}
                  </React.Suspense>
                }
              />
            );
          })}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
