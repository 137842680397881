/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MultiImageDisplay from '../Components/SlideshowComponents/MultiImageDisplay';
import PlayButton from '../Components/SlideshowComponents/PlayButton';
import React, { CSSProperties } from 'react';
import ReactPlayer from 'react-player';
import { ScreenSize, getCurrentScreenSize } from '../Services/ScreenSizeService';
import { Slides, TestimonialSlides } from '../Constants/SlideshowSlides';
import Slideshow from '../Components/SlideshowComponents/SlideShow';
import ThrusterCalculator from '../Components/ThrusterCalculator';
import './Home.css';
import '../SharedStyles.css';
import HomePageSlideShow from '../Components/SlideshowComponents/HomePageSlideShow';
import ContactSalesDialog from '../Components/ContactComponents/ContactSalesDialog';

function Home (): React.ReactElement {
  const [screenSize, setScreenSize] = React.useState(ScreenSize.MEDIUM);
  const [isCalculatorOpen, setIsCalculatorOpen] = React.useState<boolean>(false);
  const [isSalesDialogOpen, setIsSalesDialogOpen] = React.useState(false);

  const openSalesDialog = (): void => {
    setIsSalesDialogOpen(true);
  };
  const closeSalesDialog = (): void => {
    setIsSalesDialogOpen(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // Handle Screen Size Stuff
    function UpdateScreenSize (): void {
      const screenSizeCalculated = getCurrentScreenSize();
      setScreenSize(screenSizeCalculated);
    }

    window.addEventListener('resize', UpdateScreenSize);
    UpdateScreenSize();
    return () => { window.removeEventListener('resize', UpdateScreenSize); };
  }, []);

  const handleCloseCalculator = (): void => {
    if (!isCalculatorOpen) {
      setIsCalculatorOpen(true);
    } else {
      setIsCalculatorOpen(false);
    }
  };

  const commonStyles: CSSProperties = {
    border: '1px solid #ddd',
    padding: '6px',
    textAlign: 'left'
  };

  const headerStyles: CSSProperties = {
    ...commonStyles,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold'
  };

  const h4Styles: CSSProperties = {
    marginBlockEnd: '0px',
    marginBlockStart: '0px'
  };

  const checkStyles: CSSProperties = {
    color: 'green',
    marginRight: '1rem'

  };

  const xStyles: CSSProperties = {
    color: 'red',
    marginRight: '1rem'
  };

  const pricingTableContent = [
    ['Flexible Install(Bow or Stern)', 'Limited Install Positions'],
    ['No Cavitation (Silent)', 'Loud Operation'],
    ['Shallow Drafts Work!', 'Need Depth to Work'],
    ['Small Nozzels (No Drag)', 'Big Holes (Lost Displacement)'],
    ['Simple Install (DIY Friendly)', 'Complex Fiberglass Work'],
    ['Virtually Maintenance Free', 'Gears Break, Props Foul'],
    ['No Vulnerable Props', 'Exposed Shafts, Blades']
  ];

  return (
    <div style = {{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <HomePageSlideShow slides={Slides} />

      <div className='page-content-container'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jet Thruster America</title>
          <link rel="canonical" href="http://jetthrusteramerica.com/" />
          <meta
            name="description"
            content="Jet Thruster America - the superior North American thruster system. Built to be easy to install and quiet to run."
          />
        </Helmet>

        <ThrusterCalculator isCalculatorOpen={isCalculatorOpen} closeCalculator={handleCloseCalculator} />
        <ContactSalesDialog
              isSalesDialogOpen={isSalesDialogOpen}
              closeSalesDialog={closeSalesDialog}
            />
        <div className='page-option-links-container'>
          <Link className='page-optionlink' to='/systems' id='home-page-compare-systems' >
            <div className='outer-systems-button'>
              <div className='inner-button'>
                <h1 id='home-page-compare-systems' className='button-title'>
                  Systems
                </h1>
                <h5 id='home-page-compare-systems' className='button-description'>
                  <b id='home-page-compare-systems'>
                    View Available Systems
                  </b>
                </h5>
              </div>
            </div>
          </Link>
          <hr className='home-page-hr'/>
          <button
            id='home-page-calculator'
            className='page-optionlink' onClick={ () => {
              if (isCalculatorOpen) {
                setIsCalculatorOpen(false);
              } else {
                setIsCalculatorOpen(true);
              }
            } }
            type='button'
          >
            <div className='outer-calculate-button'>
              <div className='inner-button'>
                <h1 id='home-page-compare-systems' className='button-title'>
                  Calculator
                </h1>
                <h5 id='home-page-compare-systems' className='button-description'>
                  <b id='home-page-compare-systems'>
                    Calculate Thrust for your Boat
                  </b>
                </h5>
              </div>
            </div>
          </button>
          <hr className='home-page-hr'/>
          <Link className='page-optionlink' to='/contact' id='home-page-get-support'>
            <div className='outer-support-button'>
              <div className='inner-button'>
                <h1 id='home-page-compare-systems' className='button-title'>
                Support
                </h1>
                <h5 id='home-page-compare-systems' className='button-description'>
                  <b id='home-page-compare-systems'>
                    View Downloads/Resources
                  </b>
                </h5>
              </div>
            </div>
          </Link>
        </div>

        <div>
        <div className='video-title-section'>
          <h1 style = {{ marginBlockEnd: '0px', marginTop: '24px', marginLeft: window.innerWidth > 400 ? '3rem' : '1rem', marginRight: window.innerWidth > 400 ? '3rem' : '1rem' }}>It{"'"}s Just Easier...</h1>
          <small style = {{
            marginLeft: window.innerWidth > 400 ? '3rem' : '1rem',
            marginRight: window.innerWidth > 400 ? '3rem' : '1rem',
            marginBottom: '22px',
            display: 'flex'
          }}>
            {/* Better performance, silent operation, infinitely scalable */}
            Performance, Silent, and Scalable
          </small>
        </div>
        <div className="page-video-container" style={{ justifyContent: 'flex-start', fontSize: '15px', marginBottom: '.3rem' }}>
        <i>See how Jet Thruster will change your boating experience...</i>
        </div>
        <div className='page-video-container'>
          <div className='page-video'>
            <ReactPlayer
              url="https://youtu.be/MWQ3MiriCes?si=toewuuC8icGgTSM0&t=19"
              controls={true}
              light={true}
              playing={true}
              playIcon={<PlayButton />}
              width={'100%'}
            />
          </div>
          <div className='page-video-description'>
          <p>Want to make your boating easier?</p>
            <span className='page-video-link-button' onClick={openSalesDialog} style={{
              boxShadow: '1px 6px 18px 0px rgba(0, 0, 0, 0.3)'
            }}>
              <h5 style = {{ marginBlockEnd: '0px', marginBlockStart: '0px', color: 'white', fontWeight: 'normal', fontSize: '18px' }}>
                Get a Quote &nbsp; ›
              </h5>
            </span>
          </div>
        </div>
        <div className='page-video-container'>
          <p style={{ marginTop: '2.3rem', fontSize: '18px' }}>Think &quot;Fancy&quot; like a retractable, but without all the moving parts, no big holes in your boat, for the price of a tunnel thruster, except no exposed prop.. <i>It&apos;s Just Easier...</i></p>
        </div>
        <hr style = {{ border: '1px solid black', width: '75%', marginTop: '37px', marginBottom: '25px' }}/>
        <div>
          <h1 style = {{ marginBlockStart: '0px', marginLeft: window.innerWidth > 400 ? '3rem' : '1rem', marginRight: window.innerWidth > 400 ? '3rem' : '1rem' }}>Advantages</h1>
        </div>

        <table style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }} id='pricing-table'>
          <tr>
            <th style={{ width: '45%', textAlign: 'left', fontStyle: 'italic' }}><h4 style={h4Styles} >Jet Thruster Advantages</h4></th>
            <th style={{ width: '10%' }} className='pricing-table-empty-box'></th>
            <th style={{ width: '45%', textAlign: 'left', fontStyle: 'italic' }}><h4 style={h4Styles}>Legacy Thruster Drawbacks</h4></th>
          </tr>

          {
            pricingTableContent.map((row, index) => {
              return (
                <tr key={index}>
                  <td style={{ background: '#F1F1F1', padding: '.6rem', fontWeight: 'bold', color: '#494949' }}>
                    <div style={{ display: 'flex' }}> <span style={checkStyles}>✔</span>
                    <div className=''>{row[0]}</div></div>
                  </td>
                  <td className='pricing-table-empty-box'></td>

                  <td style={{ background: '#F1F1F1', padding: '.6rem', fontWeight: 'bold', color: '#494949' }}>
                    <div style={{ display: 'flex' }}>
                      <span style={{ ...xStyles, fontStyle: 'normal' }}>🚫</span>
                      <div style={{ fontStyle: 'italic' }}>
                        {row[1]}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          }
          {/* <tr>
            <td style={{ background: '#F1F1F1', padding: '.6rem', fontWeight: 'bold', color: '#494949' }}>
              <div style={{ display: 'flex' }}> <span style={checkStyles}>✔</span>
              <div className=''>Flexible Install(Bow or Stern)</div></div>
            </td>
            <td className='pricing-table-empty-box'></td>

            <td style={{ background: '#F1F1F1', padding: '.6rem', fontWeight: 'bold', color: '#494949' }}>
              <div style={{ display: 'flex' }}>
                <span style={{ ...xStyles, fontStyle: 'normal' }}>🚫</span>
                <div style={{ fontStyle: 'italic' }}>
                  Limited Install Positions
                </div>
              </div>
            </td>
          </tr> */}
        </table>

        <hr style = {{ border: '1px solid black', width: '75%', marginTop: '65px', marginBottom: '25px' }}/>
        </div>

        <div className='page-testimonials-container'>
          <h2 style = {{ marginBlockStart: '0px', marginBottom: '.2rem' }}>Jet Thruster is perfect for any size vessel</h2>
          <div className='testimonials-slideshow-container'>
          {screenSize !== 'small' ? <MultiImageDisplay slides={TestimonialSlides} /> : <Slideshow slides={TestimonialSlides} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
