export const JtWithProductImage = [
  {
    key: 'JT30',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/30/JT-30.png'
  },
  {
    key: 'JTV30',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/30/JTV-30.png'
  },
  {
    key: 'JT50',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/50/JT-50.png'
  },
  {
    key: 'JTV50',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/50/JTV-50.png'
  },
  {
    key: 'JT70',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/70/JT-70.png'
  },
  {
    key: 'JTV70',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/70/JTV-70.png'
  },
  {
    key: 'JT90',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/90/JT-90.png'
  },
  {
    key: 'JTV90',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/90/JTV-90.png'
  },
  {
    key: 'Micro',
    image:
      'https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/Systems/FullMicro.png'
  }
];
